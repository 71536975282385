import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import themeStyles from '../theme/styles';
import { ClassNameMap, StyleRules } from '@mui/styles/withStyles';

export function UseStyles(names: string[] = []): ClassNameMap {
  return makeStyles((theme: Theme) => {
    const styles = themeStyles(theme);
    if (!names.length) {
      return styles;
    } else {
      const _styles: StyleRules = {};
      names.forEach((name) => {
        _styles[name] = styles[name];
      });
      return _styles;
    }
  })();
}
