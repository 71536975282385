import React, { PropsWithChildren } from 'react';
import styles from './Col.module.scss';
import { ColProps } from './Col.interface';
import cn from 'classnames';

const Col = ({
  children,
  className,
  oneFourth,
  oneThird,
  oneHalf,
  twoThird,
  threeFourth,
  ...props
}: PropsWithChildren<ColProps>): JSX.Element => {
  return (
    <div
      {...props}
      className={cn(styles.col, className, {
        [styles.colOneFourth]: !!oneFourth,
        [styles.colOneThird]: !!oneThird,
        [styles.colOneHalf]: !!oneHalf,
        [styles.colTwoThird]: !!twoThird,
        [styles.colThreeFourth]: !!threeFourth,
      })}
    >
      {children}
    </div>
  );
};

export default Col;
