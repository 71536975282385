import custom from '../custom';

export default {
  styleOverrides: {
    root: {
      color: custom.slate,
      backgroundColor: 'transparent',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        color: 'inherit',
      },
      '& .MuiInputLabel': {
        color: custom.darkGray,
      },
    },
    underline: {
      '&:hover': {
        '&:before': {
          borderBottomColor: custom.mediumGray,
        },
      },
      '&:before': {
        display: 'none',
        borderBottomColor: custom.mediumGray,
      },
      '&.Mui-disabled': {
        '&:before': {
          display: 'none',
          borderBottomStyle: 'solid',
        },
      },
    },
    input: {
      boxSizing: 'border-box',
      fontSize: 16,
      padding: '24px 12px 10px',
      height: 56,
      background: custom.lightestGray,
      '&.Mui-disabled': {
        WebkitTextFillColor: 'inherit',
      },
    },
  },
};
