import React, { BaseSyntheticEvent, useEffect } from 'react';
import { FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { Controller, ErrorOption, UseFormMethods } from 'react-hook-form';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import MuiTextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import { ControllerCheckbox } from 'components/ControllerCheckbox';
import { UseStyles } from 'hooks';
import { postValidateEmail } from 'api/validateHouseholdMemberEmail';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { HouseholdMember } from 'api/userEnrollInfo';
import { validationPattern } from '../../../utils/helpers/helpers';
import { AxiosResponse } from 'axios';
import MuiCheckbox from '@mui/material/Checkbox';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import InfoIcon from '@mui/icons-material/Info';

const relationship: Record<string, string> = {
  spouse: 'Spouse',
  father: 'Father',
  mother: 'Mother',
  child: 'Child',
  brother: 'Brother',
  sister: 'Sister',
};

type DefaultProps = {
  emails: string[];
  trigger: () => void;
  errors: FieldErrors;
  register: () => void;
  setError: (arg0: keyof HouseholdMember, error: ErrorOption) => void;
  clearErrors: (arg0: keyof HouseholdMember) => void;
  isAbove13: boolean;
  formProcessing: boolean;
  isValidatingEmail: boolean;
  setIsValidatingEmail: (value: boolean) => void;
};

type Props = Partial<UseFormMethods> & DefaultProps;

const emailInUseError = 'That email is already in use. Please try a different email for your household member.';
const errorMessages = {
  'data.required': 'The email is required.',
  'data.constraint.string.email.user:unique': emailInUseError,
};

export function HouseholdMemberFormFields({
  emails,
  errors,
  control,
  register,
  trigger,
  setError,
  isAbove13,
  isValidatingEmail,
  setIsValidatingEmail,
  clearErrors,
}: Props): JSX.Element {
  const classes = UseStyles();
  const {
    state: { selectedPlan, user },
  } = UseCheckoutDetails();

  useEffect(() => {
    if (!isAbove13) setIsValidatingEmail(false);
  }, [isAbove13]);

  const handleValidationEmail = async (event: BaseSyntheticEvent) => {
    if (event?.target?.value === '') {
      setIsValidatingEmail(false);
      clearErrors('email');
      return;
    }

    setIsValidatingEmail(true);
    await trigger('email');
    if (!errors.email) {
      try {
        if (emails.includes(event.target.value)) {
          throw {
            data: {
              error: {
                message: emailInUseError,
              },
            },
          };
        }
        await postValidateEmail(event.target.value);
        setIsValidatingEmail(false);
      } catch (e) {
        const error = e as AxiosResponse<HttpResponseError>;
        if (!error?.data?.error) {
          throw e;
        }
        const { type, details: { code, constraint } = {}, message } = error.data.error;
        const errorId = [type, code, constraint].filter((i) => i).join('.') as keyof typeof errorMessages;
        setError('email', {
          type: 'manual',
          message: errorMessages[errorId] ?? message,
        });
        setIsValidatingEmail(false);
      }
    }
  };
  const isEmailValidated = isValidatingEmail && !Object.keys(errors).length;
  return (
    <>
      <FormGroup className={classes.fieldWrapper}>
        <ControllerCheckbox
          label='My household member is 18 years of age or older'
          name={`above_13`}
          control={control}
        />
      </FormGroup>

      <div className={classes.fieldWrapper}>
        <label className={classes.fieldLabel}>First Name*</label>
        <MuiTextField
          fullWidth
          name={`first_name`}
          variant='outlined'
          error={!!errors.first_name}
          inputProps={{ className: 'bg' }}
          inputRef={register({
            required: true,
            pattern: validationPattern.first_name,
          })}
        />
        {errors.first_name && <FormHelperText error>{errors.first_name.message}</FormHelperText>}
      </div>

      <div className={classes.fieldWrapper}>
        <label className={classes.fieldLabel}>Last name*</label>
        <MuiTextField
          fullWidth
          name={`last_name`}
          variant='outlined'
          error={!!errors.last_name}
          inputProps={{ className: 'bg' }}
          inputRef={register({
            required: true,
            pattern: validationPattern.last_name,
          })}
        />
        {errors.last_name && <FormHelperText error>{errors.last_name.message}</FormHelperText>}
      </div>

      <FormControl error={!!errors.relationship} className={classes.fieldWrapper} fullWidth>
        <label className={classes.fieldLabel}>Relationship*</label>
        <Controller
          name={`relationship`}
          control={control}
          rules={{ required: true }}
          as={
            <Select
              displayEmpty
              variant='outlined'
              fullWidth
              inputProps={{ className: 'bg' }}
              IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
            >
              <MenuItem value='' disabled>
                --- Select ---
              </MenuItem>
              {Object.keys(relationship).map((item) => (
                <MenuItem key={item} value={item}>
                  {relationship[item]}
                </MenuItem>
              ))}
            </Select>
          }
        />
      </FormControl>

      {isAbove13 && (
        <div className={classes.fieldWrapper}>
          <label className={classes.fieldLabel}>Household Member’s Email</label>
          <MuiTextField
            fullWidth
            name={`email`}
            variant='outlined'
            error={!!errors.email}
            inputProps={{ className: 'bg' }}
            inputRef={register({
              required: false,
              pattern: validationPattern.email,
            })}
            onBlur={handleValidationEmail}
          />
          {errors.email && <FormHelperText error>{errors.email.message}</FormHelperText>}
          {isEmailValidated && <FormHelperText>Validating email ...</FormHelperText>}
          <FormHelperText sx={{ fontSize: 14, display: 'flex', color: '#677796', alignItems: 'center', gap: '4px' }}>
            <InfoIcon fontSize='small' />
            You can add their email later.
          </FormHelperText>
        </div>
      )}

      {!!selectedPlan?.travel_protection?.length && user.travel_protection !== null && (
        <div className={classes.fieldWrapper}>
          <FormControlLabel
            control={
              <Controller
                label={'Add Travel Protection'}
                name={'travel_protection'}
                control={control}
                render={({ onChange, value }) => {
                  return <MuiCheckbox checked={value} color='primary' onChange={(e) => onChange(e.target.checked)} />;
                }}
              />
            }
            label={'Add Travel Protection'}
          />
        </div>
      )}
    </>
  );
}
