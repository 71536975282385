import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import WarnTriangleIcon from '@mui/icons-material/Warning';
import { WarnCircleIcon } from '@theme/icons';
import Button from '@mui/material/Button';
import NextLink from 'next/link';
import styles from './AlertBlock.module.scss';
import { AlertBlockVariant } from './AlertBlock.enum';
import { AlertBlockProps } from './AlertBlock.interface';
import cn from 'classnames';

const AlertBlock: React.FC<AlertBlockProps> = ({ onClose, title, description, buttonText, paymentLink, variant }) => {
  return (
    <div
      className={cn(styles.AlertBlock, {
        [styles.ErrorBlock]: variant === AlertBlockVariant.Error,
        [styles.WarningBlock]: variant === AlertBlockVariant.Warning,
        [styles.SuccessBlock]: variant === AlertBlockVariant.Success,
      })}
    >
      {onClose && (
        <IconButton className={styles.ErrorBlockClose} onClick={onClose}>
          <CloseIcon fontSize='inherit' />
        </IconButton>
      )}
      {variant === AlertBlockVariant.Error && <WarnTriangleIcon className='icon' />}
      {variant === AlertBlockVariant.Warning && <WarnTriangleIcon className='icon' />}
      {variant === AlertBlockVariant.Success && <WarnCircleIcon className='icon' />}
      <div
        className={cn(styles.AlertBlockContent, {
          [styles.ErrorBlockContent]: variant === AlertBlockVariant.Error,
          [styles.WarningBlockContent]: variant === AlertBlockVariant.Warning,
          [styles.SuccessBlockContent]: variant === AlertBlockVariant.Success,
        })}
      >
        <div
          className={cn(styles.AlertBlockTitle, {
            [styles.ErrorBlockTitle]: variant === AlertBlockVariant.Error,
            [styles.WarningBlockTitle]: variant === AlertBlockVariant.Warning,
            [styles.SuccessBlockTitle]: variant === AlertBlockVariant.Success,
          })}
        >
          {title}
        </div>
        {description && (
          <div
            className={cn(styles.AlertBlockDescription, {
              [styles.ErrorBlockDescription]: variant === AlertBlockVariant.Error,
              [styles.WarningBlockDescription]: variant === AlertBlockVariant.Warning,
              [styles.SuccessBlockDescription]: variant === AlertBlockVariant.Success,
            })}
          >
            {description}
          </div>
        )}
      </div>
      {paymentLink && (
        <NextLink href={paymentLink} passHref>
          <Button color='primary' variant='outlined' size='small' className={styles.ErrorBlockBtn}>
            {buttonText}
          </Button>
        </NextLink>
      )}
    </div>
  );
};

export default AlertBlock;
