import React, { useEffect, useState } from 'react';
import container from '../services/container';
import authApi from 'services/api/auth';
import { useConfig } from 'hooks/useConfig';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Kustomer: any;
  }
}

type LoadingStatus = 'start' | 'loading' | 'error' | 'done';
type ReturnProps = {
  element_id: null | string;
  do_load: (id: string) => (e: React.MouseEvent) => void;
  status: LoadingStatus;
};

export function useKustomer(): ReturnProps {
  const appshell = container.get('appshell');
  const authUser = appshell?.user ?? null;
  const { configVar: kustomerApiKey } = useConfig('kustomer_api_key');
  const { configVar: kustomerApiBrand } = useConfig('kustomer_api_brand');
  const [status, setStatus] = useState<LoadingStatus>('start');
  const [initiateSign, setInitiateSign] = useState(false);
  const [elementId, setElementId] = useState<null | string>(null);
  useEffect(() => {
    const kustomerInit = () => {
      window.Kustomer?.start(
        {
          brandId: kustomerApiBrand,
        },
        function () {
          setStatus('done');
        }
      );
    };
    if (status === 'done') {
      openChat();
      window.removeEventListener('kustomerLoaded', kustomerInit);
    }
    window.addEventListener('kustomerLoaded', kustomerInit);
    return () => window.removeEventListener('kustomerLoaded', kustomerInit);
  }, [status]);
  useEffect(() => {
    (async () => {
      if (status === 'done' && authUser && !initiateSign) {
        setInitiateSign(true);
        const { token } = (await authApi.token()) as unknown as { token: string };
        window.Kustomer.login(
          {
            jwtToken: token,
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (_loginCallbackResponse: any, error: Error) => {
            if (error) {
              console.error(error);
            }
          }
        );
      }
    })();
  }, [status, authUser]);
  const new_script = (src: string, id: string, dataKustomerApiKey: string) => {
    return new Promise<void>(function (resolve, reject) {
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      if (dataKustomerApiKey) {
        script.setAttribute('data-kustomer-api-key', dataKustomerApiKey);
      }
      script.addEventListener('load', function () {
        resolve();
      });
      script.addEventListener('error', function (e) {
        reject(e);
      });
      document.body.appendChild(script);
    });
  };
  const do_load = (id: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    setElementId(id);
    if (status !== 'done') {
      setStatus('loading');
      const kustomer_script = new_script(
        'https://cdn.kustomerapp.com/chat-web/widget.js',
        'kustomer-snippet',
        kustomerApiKey!
      );
      kustomer_script.catch(() => {
        setStatus('error');
      });
    } else {
      openChat();
    }
  };
  const openChat = () => {
    if (status === 'done') {
      window.Kustomer?.open();
    }
  };

  return { do_load, status, element_id: elementId };
}
