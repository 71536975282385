import React from 'react';
import { UseStyles } from 'hooks';
import { PurchaseBriefPromotionOutputBanner } from 'services/api/client';
import { ensureText } from 'utils';

type Props = {
  banner: PurchaseBriefPromotionOutputBanner | null;
};

const initBanner = {
  title: '',
  body: '',
  image: '',
};

export function PromotionBannerBlock({ banner }: Props): JSX.Element {
  const currentBanner = banner || initBanner;
  const classes = UseStyles();
  const backgroundImage = currentBanner.image || null;

  return (
    <div className={classes.promotionBanner}>
      {backgroundImage && <img className={classes.promotionBannerCustomImage} src={backgroundImage} alt='' />}
      <div className={classes.promotionBannerTitle}>{ensureText(currentBanner.title ?? '', true)}</div>
      {currentBanner.body && (
        <div
          className={classes.promotionBannerBody}
          dangerouslySetInnerHTML={{ __html: ensureText(currentBanner.body, true) }}
        />
      )}
    </div>
  );
}
