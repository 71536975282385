import custom from '../custom';

export default {
  styleOverrides: {
    root: {
      padding: 5,
      color: custom.darkGray,
    },
    colorPrimary: {
      '&.Mui-disabled': {
        color: custom.mediumGray,
      },
    },
    colorSecondary: {
      color: custom.darkGray,
      '&.Mui-checked': {
        color: custom.darkGray,
      },
    },
  },
};
