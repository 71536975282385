import React from 'react';
import styles from './SidebarMenu.module.scss';
import { SidebarIcon } from '@ui/molecules/SidebarIcon/SidebarIcon';
import { SidebarMenuProps } from './SidebarMenu.interface';

export const SidebarMenu = ({ items, routerAsPath }: SidebarMenuProps): JSX.Element => {
  return (
    <>
      <nav className={styles.accountSidebarNav}>
        {items.map(({ icon, label, appPath, target, onClick }) => (
          <SidebarIcon
            key={appPath}
            icon={icon}
            label={label}
            appPath={appPath}
            routerAsPath={routerAsPath}
            target={target}
            onClick={onClick ?? undefined}
          />
        ))}
      </nav>
    </>
  );
};
