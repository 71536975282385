import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import custom from '../custom';

export default {
  styleOverrides: {
    root: {
      color: custom.slate,
      borderRadius: 2,
      '&.Mui-error': {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: custom.orange,
        },
      },
    },
    notchedOutline: {
      borderColor: custom.border,
    },
    input: {
      boxSizing: 'border-box',
      fontSize: 16,
      padding: 14,
      height: 50,
      '&.Mui-disabled': {
        background: 'rgba(240, 241, 244, 0.3)',
      },
    },
  },
};
