import React from 'react';
import styles from './SidebarMember.module.scss';
import { SidebarMemberProps } from './SidebarMember.interface';

export const SidebarMember = ({ children, topInfo }: SidebarMemberProps): JSX.Element => {
  return (
    <div className={styles.accountSidebarMember}>
      {topInfo && <div>{topInfo}</div>}
      <div className={styles.accountSidebarMemberInfo}>{children}</div>
    </div>
  );
};
