import React from 'react';
import Typography from '@mui/material/Typography';
import { UseStyles } from 'hooks';
import { CardElementComponent } from '@stripe/react-stripe-js';
import { UseFormMethods } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { ControllerCheckbox } from 'components/ControllerCheckbox';
import FormGroup from '@mui/material/FormGroup';
import { CreditCardBlock } from './CreditCardBlock';
import { PurchaseCardInfoOutput } from 'services/api/client';
import Account from 'services/Account';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import { LinearProgress } from '@mui/material';
import Button from '@mui/material/Button';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { CreditCardIcon, GooglePayIcon, ApplePayIcon } from 'theme/icons';

type Props = Partial<UseFormMethods> & {
  CardElement: CardElementComponent;
  errors: FieldErrors;
  register: () => void;
  clearErrors: () => void;
  setPayWithWallet: (status: boolean) => void;
  isCardChecked: boolean;
  idNoChargeChecked: boolean;
  isPayingWithWallet: boolean;
  enabledWalletMethod?: string;
  card: PurchaseCardInfoOutput | null | undefined;
};

export function PaymentOption({
  setPayWithWallet,
  CardElement,
  register,
  errors,
  clearErrors,
  control,
  isCardChecked,
  idNoChargeChecked,
  isPayingWithWallet,
  enabledWalletMethod,
  card,
}: Props): JSX.Element {
  const classes = UseStyles();
  const {
    state: {
      previewDetails: { isLoading: isValidating },
    },
  } = UseCheckoutDetails();
  return (
    <div className={`${classes.box} ${classes.completeInformationBlock}`}>
      <Typography component='h3' variant='h3'>
        Choose your payment method
      </Typography>
      {!idNoChargeChecked && card && (
        <FormGroup className={classes.fieldWrapper}>
          <ControllerCheckbox
            label={`Use the credit card on file ending in ${card.last4}`}
            name='cardCheck'
            control={control}
          />
        </FormGroup>
      )}
      {!idNoChargeChecked && !isCardChecked && !enabledWalletMethod && (
        <CreditCardBlock CardElement={CardElement} register={register} errors={errors} clearErrors={clearErrors} />
      )}
      {!idNoChargeChecked && !isCardChecked && !!enabledWalletMethod && (
        <div className={classes.completeInformationMethods}>
          <div className={`${classes.completeInformationMethod} item-0 ${!isPayingWithWallet ? 'active' : ''}`}>
            <Button className={classes.completeInformationMethodBtn} onClick={() => setPayWithWallet(false)}>
              {!isPayingWithWallet ? (
                <RadioButtonCheckedIcon className='mark' />
              ) : (
                <RadioButtonUncheckedIcon className='mark' />
              )}
              <>
                <CreditCardIcon className='icon' />
                Credit or Debit Card
              </>
            </Button>
            {!isPayingWithWallet && (
              <div className={classes.completeInformationMethodContent}>
                <CreditCardBlock
                  CardElement={CardElement}
                  register={register}
                  errors={errors}
                  clearErrors={clearErrors}
                  noBorder
                />
              </div>
            )}
          </div>
          <div className={`${classes.completeInformationMethod} item-1 ${isPayingWithWallet ? 'active' : ''}`}>
            <Button className={classes.completeInformationMethodBtn} onClick={() => setPayWithWallet(true)}>
              {isPayingWithWallet ? (
                <RadioButtonCheckedIcon className='mark' />
              ) : (
                <RadioButtonUncheckedIcon className='mark' />
              )}
              {enabledWalletMethod === 'apple-pay' ? (
                <ApplePayIcon className='applePayIcon' />
              ) : (
                <GooglePayIcon className='googlePayIcon' />
              )}
            </Button>
            {isPayingWithWallet && (
              <div className={classes.completeInformationMethodContent}>
                Another step will appear to securely submit your payment information.
              </div>
            )}
          </div>
        </div>
      )}
      {Account.roleAdmin() && (
        <>
          <FormGroup className={classes.fieldWrapper}>
            <ControllerCheckbox
              disabled={isValidating}
              label={`Would you like to process without charge?`}
              name='noCharge'
              control={control}
            />
            {isValidating && <LinearProgress />}
          </FormGroup>
        </>
      )}
    </div>
  );
}
