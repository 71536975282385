import React from 'react';
import { UseStyles } from 'hooks';
import { DialogDefault } from 'components/Dialog/DialogDefault';
import { useDialog } from 'hooks/useDialog';
import Button from '@mui/material/Button';
import {
  HeadsetMicIcon,
  TravelIcon,
  SupportAgentIcon,
  SecureIcon,
  RxDiscountIcon,
  CalendarIcon,
  GetSupportIcon,
} from '../../theme/icons';
import withPathPrefix from 'utils/withPathPrefix';
import { useStoreon } from 'storeon/react';
import { Kustomer } from 'containers/Widget/Kustomer';
import { ensureUrl } from 'utils';

export interface ComponentProps {
  name: string;
}

export function CancelPlanFullDialog({ name }: ComponentProps): JSX.Element {
  const classes = UseStyles();
  const { isOpen, close } = useDialog(name);
  const { dispatch } = useStoreon();
  return (
    <DialogDefault open={isOpen} onClose={close} title={'Cancel Plan'}>
      <div className={classes.accountDialogCancelPlanFull}>
        <div className={classes.accountDialogCancelPlanTitle}>
          We’re sad to see you go.
          <br />
          Did you know about these valuable benefits?
        </div>
        <div className={classes.accountDialogCancelPlanFullContent}>
          <div className={classes.accountDialogCancelPlanFullContentTitle}>It’s so much more than great discounts.</div>
          <div className={classes.accountDialogCancelPlanFullBenefitsRow}>
            <div className={classes.accountDialogCancelPlanFullBenefitsCol}>
              <a
                className={classes.accountDialogCancelPlanFullBenefit}
                rel='noreferrer'
                target='_blank'
                href={ensureUrl('https://www.wesalute.com/prescription-drug-discount')}
              >
                <RxDiscountIcon className='icon' />
                RX Discount
              </a>
            </div>
            <div className={classes.accountDialogCancelPlanFullBenefitsCol}>
              <a
                className={classes.accountDialogCancelPlanFullBenefit}
                rel='noreferrer'
                target='_blank'
                href={ensureUrl('https://www.wesalute.com/benefits/jobs-and-career-network')}
              >
                <SupportAgentIcon className='icon' />
                Jobs & Career Network
              </a>
            </div>
            <div className={classes.accountDialogCancelPlanFullBenefitsCol}>
              <a
                className={classes.accountDialogCancelPlanFullBenefit}
                rel='noreferrer'
                target='_blank'
                href={ensureUrl('https://www.wesalute.com/secure-military-id')}
              >
                <SecureIcon className='icon' />
                Secure Military ID
              </a>
            </div>
            <div className={classes.accountDialogCancelPlanFullBenefitsCol}>
              <a
                className={classes.accountDialogCancelPlanFullBenefit}
                rel='noreferrer'
                target='_blank'
                href={ensureUrl('https://www.wesalute.com/live-vip-member-support-vetrewards')}
              >
                <HeadsetMicIcon className='icon' />
                VIP Customer Support
              </a>
            </div>
            <div className={classes.accountDialogCancelPlanFullBenefitsCol}>
              <a
                className={classes.accountDialogCancelPlanFullBenefit}
                rel='noreferrer'
                target='_blank'
                href={ensureUrl('https://www.wesalute.com/travel-protection')}
              >
                <TravelIcon className='icon' />
                Discounted Travel Protection
              </a>
            </div>
            <div className={classes.accountDialogCancelPlanFullBenefitsCol}>
              <a
                className={classes.accountDialogCancelPlanFullBenefit}
                rel='noreferrer'
                target='_blank'
                href={ensureUrl('https://www.wesalute.com/benefits/community-travel')}
              >
                <CalendarIcon className='icon' />
                WeSalute+ Travel
              </a>
            </div>
          </div>
          <div className={classes.accountDialogCancelPlanFullExclusivesRow}>
            <div className={classes.accountDialogCancelPlanFullExclusivesCol}>
              <a
                className={classes.accountDialogCancelPlanFullExclusive}
                rel='noreferrer'
                target='_blank'
                href={ensureUrl('https://www.wesalute.com/offers/1800flowers-honors-you-30-vetrewards-exclusives')}
              >
                <div className={classes.accountDialogCancelPlanFullExclusiveImage}>
                  <img className='img' src={withPathPrefix('/images/1800flowers.jpg')} alt='1800Flowers.com' />
                </div>
                <div className={classes.accountDialogCancelPlanFullExclusiveType}>WeSalute+</div>
                <div className={classes.accountDialogCancelPlanFullExclusiveLogo}>
                  <img
                    className='logo'
                    src={withPathPrefix('/images/1800flowers-logo.png')}
                    alt='1800Flowers.com logo'
                  />
                </div>
                <div className={classes.accountDialogCancelPlanFullExclusiveDescription}>
                  1800Flowers.com Honors You with 30% OFF Exclusive Savings
                </div>
              </a>
            </div>
            <div className={classes.accountDialogCancelPlanFullExclusivesCol}>
              <a
                className={classes.accountDialogCancelPlanFullExclusive}
                rel='noreferrer'
                target='_blank'
                href={ensureUrl(
                  'https://www.wesalute.com/offers/jetblue-honors-you-5-vetrewards-exclusive-two-free-bags'
                )}
              >
                <div className={classes.accountDialogCancelPlanFullExclusiveImage}>
                  <img className='img' src={withPathPrefix('/images/jetblue.jpg')} alt='JetBlue' />
                </div>
                <div className={classes.accountDialogCancelPlanFullExclusiveType}>WeSalute+</div>
                <div className={classes.accountDialogCancelPlanFullExclusiveLogo}>
                  <img className='logo' src={withPathPrefix('/images/jetblue-logo.png')} alt='JetBlue logo' />
                </div>
                <div className={classes.accountDialogCancelPlanFullExclusiveDescription}>
                  JetBlue Honors You with 5% OFF WeSalute+ Exclusive + Two Free Bags
                </div>
              </a>
            </div>
          </div>
          <div className={classes.accountDialogCancelPlanFullContentBtnWrapper}>
            <Kustomer
              buttonText={
                <>
                  <GetSupportIcon className='icon' />
                  Get Support to Learn More
                </>
              }
              className={classes.accountDialogCancelPlanFullContentBtn}
              color='primary'
              variant='contained'
            />
          </div>
          <Button
            className={classes.accountDialogCancelPlanCancel}
            onClick={() => {
              close();
              dispatch('dialog/open', { id: 'autorenewSubscription' });
            }}
          >
            Continue and cancel plan
          </Button>
        </div>
      </div>
    </DialogDefault>
  );
}
