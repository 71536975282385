import React, { useState } from 'react';
import { UseStyles } from 'hooks';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

type Props = {
  children?: React.ReactNode;
  element: (props: { toggle: () => void }) => JSX.Element;
  render?: (props: { toggle: () => void }) => JSX.Element;
};

export function TooltipDialog({ children, element, render }: Props): JSX.Element {
  const classes = UseStyles();
  const [openTooltip, setOpenTooltip] = useState(false);
  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };
  const toggleTooltipOpen = () => {
    setOpenTooltip(!openTooltip);
  };
  const TooltipContent = () => {
    return (
      <div className={classes.accountTooltip}>
        <IconButton className={classes.accountTooltipClose} onClick={handleTooltipClose}>
          <CloseIcon fontSize='inherit' />
        </IconButton>
        {render ? render({ toggle: toggleTooltipOpen }) : children}
      </div>
    );
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          arrow
          placement='top-start'
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={openTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={<TooltipContent />}
        >
          {element({ toggle: toggleTooltipOpen })}
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}
