import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import MuiTextField from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { OutlinedInputProps } from '@mui/material';

type Props = Partial<UseFormMethods> & {
  name: string;
  defaultValue: string | undefined;
  isPost: boolean;
  onBlur?: OutlinedInputProps['onBlur'];
  errors: FieldErrors;
  register: () => void;
};

export function CityField({ name, errors, control, register, defaultValue, isPost, onBlur }: Props): JSX.Element {
  if (isPost) {
    return (
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        defaultValue={defaultValue}
        as={
          <Select
            displayEmpty
            variant='outlined'
            fullWidth
            IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
          >
            <MenuItem value='' disabled>
              --- Select ---
            </MenuItem>
            <MenuItem value='APO'>APO</MenuItem>
            <MenuItem value='FPO'>FPO</MenuItem>
            <MenuItem value='DPO'>DPO</MenuItem>
          </Select>
        }
      />
    );
  }

  return (
    <MuiTextField
      fullWidth
      name={name}
      onBlur={onBlur}
      variant='outlined'
      placeholder='City'
      error={!!errors[name]}
      inputRef={register({ required: true })}
    />
  );
}
