import { useEffect, useState } from 'react';
import checkoutApi from 'services/api/checkout';
import Cookies from 'services/Cookies';
import { EnrollPromoOutput } from 'services/api/client';
import { ensureUrl } from 'utils';

type ReturnType = {
  loaded: boolean;
};

export const usePromotion = (): ReturnType => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    (async () => {
      if (Cookies.get('promo')) {
        try {
          const data = (await checkoutApi.promotionApply({
            data: Cookies.get('promo')!,
          })) as unknown as EnrollPromoOutput;
          Cookies.remove('promo');
          if (data?.redirect_url) {
            window.location.href = ensureUrl(data.redirect_url);
          }
        } catch (e) {
          /* continue regardless of error */
        }
      }
      setLoaded(true);
    })();
  }, []);
  return {
    loaded,
  };
};
