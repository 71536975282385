import { baseAttributes } from './helpers';

export default function checkoutStepViewed({ step, title }: Data): Data {
  return {
    ...baseAttributes(),
    step,
    title,
    url: process.browser && window.location.href,
  };
}
