import custom from '../custom';

export default {
  styleOverrides: {
    body: {
      color: custom.slate,
    },
    head: {
      color: custom.slate,
    },
  },
};
