import React from 'react';
import Button from '@mui/material/Button';
import { CircularProgress, ButtonProps } from '@mui/material';
import { useKustomer } from 'hooks';

type Props = {
  buttonText?: string | React.ReactNode;
  prefixButtonText?: string;
  suffixButtonText?: string;
} & ButtonProps;

export function Kustomer({
  buttonText = 'Contact us',
  prefixButtonText = '',
  suffixButtonText = '',
  onClick,
  ...buttonProps
}: Props): JSX.Element {
  const { do_load, status } = useKustomer();
  const buttonComponent = (
    <Button
      href='#'
      onClick={(e) => {
        do_load('update')(e);
        !!onClick && onClick(e);
      }}
      style={'loading' === status ? { opacity: 0.5 } : {}}
      {...buttonProps}
    >
      {buttonText}
      {'loading' === status && <CircularProgress size={12} />}
    </Button>
  );
  return (
    <div className='kustomer'>
      {prefixButtonText ? `${prefixButtonText} ` : ''}
      {buttonComponent}
      {suffixButtonText ? ` ${suffixButtonText}` : ''}
    </div>
  );
}
