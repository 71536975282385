import custom from '../custom';

export default {
  styleOverrides: {
    root: {
      padding: 5,
      color: custom.darkGray,
    },
  },
};
