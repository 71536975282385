import React from 'react';
import { MemberInfoOutputPlanTypeEnum } from 'services/api/client';

type Props = {
  planType: MemberInfoOutputPlanTypeEnum;
};

export function AddHouseholdMemberText({ planType }: Props): JSX.Element {
  const billingTypes = {
    lifetime: 'annually',
  };
  const billingType = billingTypes[planType as keyof typeof billingTypes];
  const digital = ['trial', 'month'].includes(planType);
  return (
    <>
      You can add up to 4 members of your household today! Each will have their own account and receive their own{' '}
      {digital ? 'Digital ' : ''}
      WeSalute+ card.
      {!!billingType && <span>*Household member subscriptions will be billed {billingType}</span>}
    </>
  );
}
