import React, { useContext, useState, useEffect } from 'react';
import { UseStyles } from 'hooks';
import AppshellContext from '@inc/AppshellContext';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Button from '@mui/material/Button';
import {
  WeSaluteLogo,
  VerificationStatusTrueIcon,
  VerificationStatusFalseIcon,
  AccountIcon,
  LogoutIcon,
  LoginIcon,
} from 'theme/icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MemberInfoOutput } from 'services/api/client';
import { useMemberApi } from 'hooks/useMemberApi';
import Cookies from 'services/Cookies';
import PersonIcon from '@mui/icons-material/Person';
import { ensureUrl } from 'utils';

const domain = ensureUrl(process.env.NEXT_PUBLIC_PLATFORM_APP_URL || '');

export function HeaderDefault({
  showMenu = false,
  hideUserMenu,
}: {
  showMenu?: boolean;
  hideUserMenu?: boolean;
}): JSX.Element {
  const classes = UseStyles();
  const userContext = useContext(AppshellContext);
  const user = userContext?.user?.data;
  const auth = userContext?.auth;
  const redirectTo = userContext?.goto;
  const { data } = useMemberApi<MemberInfoOutput>('memberInfo');

  const router = useRouter();

  const [openHeaderMobileMenu, setOpenHeaderMobileMenu] = useState(false);

  const [anchorUserMenuEl, setAnchorUserMenuEl] = useState<HTMLElement | null>(null);
  const openUserMenu = Boolean(anchorUserMenuEl);

  const handleUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserMenuEl(event.currentTarget);
  };

  const handleUserClose = () => {
    setAnchorUserMenuEl(null);
  };

  const masqueraded = !!Cookies.get('masquerade') || !!data?.masqueraded;
  const unmasqueradeHandler = () => (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const masqueraded_to = Cookies.get('masquerade');
    if (masqueraded_to) {
      Cookies.set('unmasquerade_track', masqueraded_to);
    }
    Cookies.remove('masquerade');
    window.location.href = ensureUrl(`${process.env.NEXT_PUBLIC_ADMIN_APP_URL}/unmasquerade`);
  };

  const HeaderMenuItem = ({ label, href }: { label: string; href: string }) => {
    return (
      <>
        {href.indexOf(domain) == -1 ? (
          //Internal links
          <Link href={href}>
            <a className={`${classes.newHeaderMenuItem} ${router.pathname == href ? 'active' : ''}`}>{label}</a>
          </Link>
        ) : (
          //External links
          <a className={classes.newHeaderMenuItem} href={href}>
            {label}
          </a>
        )}
      </>
    );
  };

  const HeaderItemsBlock = () => {
    const marketingUrl = ensureUrl(process.env.NEXT_PUBLIC_MARKETING_APP_URL!);
    return (
      <>
        <HeaderMenuItem label='About' href={`${marketingUrl}/about`} />
        <HeaderMenuItem label='Plans & Pricing' href={`${marketingUrl}/plans`} />
        <HeaderMenuItem label='Blog' href={`${marketingUrl}/blog`} />
        <HeaderMenuItem label='Benefits' href={`${marketingUrl}/benefits`} />
        <HeaderMenuItem label='Marketplace' href={`${marketingUrl}/marketplace`} />
      </>
    );
  };

  // Add body class for account pages.
  useEffect(() => {
    document.body.classList.add('page-account');
    return () => {
      document.body.classList.remove('page-account');
    };
  }, []);
  const titleMap = {
    year: 'Annual',
    month: 'Monthly',
    trial: 'Trial',
    '3year': '3 Year',
    '5year': '5 Year',
    lifetime: 'Lifetime',
  };
  if (!user) {
    return <></>;
  }
  return (
    <>
      <Typography component='div' variant='body2' className={classes.newHeaderWrapper}>
        <div className={classes.newHeaderSpace} />
        <div className={classes.newHeader}>
          <div className={classes.newHeaderInner}>
            <div className={classes.newHeaderLogoWrapper}>
              <WeSaluteLogo className={classes.newHeaderLogo} />
            </div>
            {showMenu && (
              <nav className={classes.newHeaderNav}>
                <HeaderItemsBlock />
              </nav>
            )}
            {!hideUserMenu && (
              <>
                <div className={classes.newHeaderDesktopUserMenu}>
                  <Button
                    aria-controls='user-menu'
                    aria-haspopup='true'
                    className={classes.newHeaderDesktopUserMenuBtn}
                    onClick={handleUserMenuClick}
                  >
                    <PersonIcon className={classes.newHeaderDesktopUserMenuIcon} />
                  </Button>
                  <Menu
                    id='user-menu'
                    PopoverClasses={{
                      paper: classes.newHeaderDesktopUserMenuPaper,
                    }}
                    MenuListProps={{ className: classes.newHeaderDesktopUserMenuList }}
                    keepMounted
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    anchorEl={anchorUserMenuEl}
                    open={openUserMenu}
                    onClose={handleUserClose}
                  >
                    <div className={classes.newHeaderUserMenu}>
                      <div className={classes.newHeaderUserMenuTop}>
                        <div className={classes.newHeaderUserMenuUsername}>
                          {data?.first_name} {data?.last_name}{' '}
                          {!!data &&
                            (data.verified ? (
                              <VerificationStatusTrueIcon className='icon' />
                            ) : (
                              <VerificationStatusFalseIcon className='icon' />
                            ))}
                        </div>
                        <div className={classes.newHeaderUserMenuInfo}>
                          <b>MEMBER ID:</b>
                          {data?.member_id}
                        </div>
                        <div className={classes.newHeaderUserMenuInfo}>
                          <b>Travel protection:</b>
                          {data?.travel_protection ? 'Active' : 'Inactive'}
                        </div>
                        <div className={classes.newHeaderUserMenuInfo}>
                          <b>Plan:</b>
                          {data?.plan_type ? titleMap[data.plan_type] : 'None'}
                        </div>
                      </div>
                      <div className={classes.newHeaderUserMenuBottom}>
                        <div className={classes.newHeaderUserMenuItems}>
                          <Link href={`${ensureUrl(process.env.NEXT_PUBLIC_ACCOUNT_APP_URL!)}/profile`}>
                            <MenuItem className={classes.newHeaderUserMenuItem}>
                              <AccountIcon className='icon' />
                              My Account
                            </MenuItem>
                          </Link>
                          {masqueraded && (
                            <Link href='#'>
                              <MenuItem className={classes.newHeaderUserMenuItem}>
                                <div onClick={unmasqueradeHandler()}>
                                  <LoginIcon className='icon' />
                                  Unmasquerade
                                </div>
                              </MenuItem>
                            </Link>
                          )}
                          <MenuItem
                            className={classes.newHeaderUserMenuItem}
                            onClick={async () => {
                              if (!auth || !redirectTo) {
                                return;
                              }
                              await auth().signOut();
                              redirectTo('auth/signout', {
                                return_to: window.location.href,
                              });
                            }}
                          >
                            <LogoutIcon className='icon' />
                            Logout
                          </MenuItem>
                        </div>
                      </div>
                    </div>
                  </Menu>
                </div>
                <Button
                  onClick={() => {
                    setOpenHeaderMobileMenu((prev) => !prev);
                  }}
                  className={`${classes.newHeaderToggle} ${openHeaderMobileMenu ? 'active' : ''}`}
                >
                  <span className='sr-only'>Toggle navigation</span>
                  <span className='icon-bar'></span>
                  <span className='icon-bar'></span>
                  <span className='icon-bar'></span>
                </Button>
                <div className={`${classes.newHeaderMobileNavbar} ${openHeaderMobileMenu ? 'open' : ''}`}>
                  <div className={classes.newHeaderUserMenu}>
                    <div className={classes.newHeaderUserMenuTop}>
                      <div className={classes.newHeaderUserMenuUsername}>
                        {data?.first_name} {data?.last_name}{' '}
                        {!!data &&
                          (data.verified ? (
                            <VerificationStatusTrueIcon className='icon' />
                          ) : (
                            <VerificationStatusFalseIcon className='icon' />
                          ))}
                      </div>
                      <div className={classes.newHeaderUserMenuInfo}>
                        <b>MEMBER ID:</b>
                        {data?.member_id}
                      </div>
                      <div className={classes.newHeaderUserMenuInfo}>
                        <b>Travel protection:</b>
                        {data?.travel_protection ? 'Active' : 'Inactive'}
                      </div>
                      <div className={classes.newHeaderUserMenuInfo}>
                        <b>Plan:</b>
                        {data?.plan_type ? titleMap[data.plan_type] : 'None'}
                      </div>
                    </div>
                    <div className={classes.newHeaderUserMenuBottom}>
                      <div className={classes.newHeaderUserMenuItems}>
                        <Link href={`${ensureUrl(process.env.NEXT_PUBLIC_ACCOUNT_APP_URL!)}/profile`}>
                          <MenuItem className={classes.newHeaderUserMenuItem}>
                            <AccountIcon className='icon' />
                            My Account
                          </MenuItem>
                        </Link>
                        {masqueraded && (
                          <Link href='#'>
                            <MenuItem className={classes.newHeaderUserMenuItem}>
                              <div onClick={unmasqueradeHandler()}>
                                <LoginIcon className='icon' />
                                Unmasquerade
                              </div>
                            </MenuItem>
                          </Link>
                        )}
                        <MenuItem
                          className={classes.newHeaderUserMenuItem}
                          onClick={async () => {
                            if (!auth || !redirectTo) {
                              return;
                            }
                            await auth().signOut();
                            redirectTo('auth/signout', {
                              return_to: window.location.href,
                            });
                          }}
                        >
                          <LogoutIcon className='icon' />
                          Logout
                        </MenuItem>
                      </div>
                    </div>
                  </div>
                  {showMenu && (
                    <nav className={classes.newHeaderMobileNav}>
                      <HeaderItemsBlock />
                    </nav>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Typography>
    </>
  );
}
