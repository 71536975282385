import { dbRef } from '../../Firebase';
import { MiddlewareProps } from '../middleware';

export default async function vrMember({
  index,
  helpers: { getPrev },
  user,
  config = {},
}: MiddlewareProps): Promise<undefined | Data> {
  const { reverse = false, resolver } = config;
  if (!user || !user.uid) {
    const prev = getPrev('redirect', index);
    return {
      action: 'redirect',
      destination: prev && 'auth' === prev.destination ? 'checkout' : 'auth',
    };
  }
  const doc = await dbRef.collection('veterans').doc(user.uid).get();
  const { roles = [] } = doc.exists ? (doc.data() as { roles: string[] }) : {};
  if (!reverse && !roles.filter((role) => role.startsWith('vr_')).length) {
    return resolver
      ? resolver({ user })
      : {
          action: 'redirect',
          destination: 'checkout',
        };
  }
}
