import React, { PropsWithChildren } from 'react';
import styles from './FieldError.module.scss';
import { FieldErrorProps } from './FieldError.interface';
import cn from 'classnames';

const FieldError = ({ size = 'small', children, ...props }: PropsWithChildren<FieldErrorProps>): JSX.Element => {
  return (
    <>
      {children && (
        <div
          {...props}
          className={cn({
            [styles.FieldContainerErrorMedium]: size === 'medium',
            [styles.FieldContainerError]: size === 'small',
          })}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default FieldError;
