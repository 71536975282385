import React from 'react';
import { getConvertedPrice } from 'utils';
import { UseStyles } from 'hooks';
import { PriceOutput, PurchaseBriefLineItemOutputDiscountReason } from 'services/api/client';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';

export function DiscountPrice({
  price,
  reason,
  type,
}: {
  price: PriceOutput;
  reason?: PurchaseBriefLineItemOutputDiscountReason;
  type: string;
}): JSX.Element | null {
  const classes = UseStyles();
  const {
    state: { promoId },
  } = UseCheckoutDetails();
  if (!price.discount) return null;
  const saleType =
    !!promoId && ['plan', 'subscription_extension'].includes(type) && 'promotional_discount' === reason?.type;
  return (
    <div className={`${classes.reviewDetail} ${classes.reviewDetailPromo}`}>
      <span>{saleType ? 'Additional discount on your plan' : reason?.description ?? 'Discount'}</span>-
      {getConvertedPrice(price.discount)}
    </div>
  );
}
