import * as React from 'react';
import spacetime from 'spacetime';
import soft from 'timezone-soft';
import allTimezones from 'data/timezones.json';

type ITimezoneOption = {
  value: string;
  label: string;
  abbrev?: string;
  altName?: string;
  offset: number;
};

type CategoryItem = {
  id: string;
  name: string;
};

const parseTz = (zone: [string, string]) => {
  try {
    const now = spacetime.now(zone[0]);
    const tz = now.timezone();
    const tzStrings = soft(zone[0]);

    const standardAbbr = tzStrings?.[0]?.standard?.abbr ?? '';
    const dstAbbr = tzStrings?.[0]?.daylight?.abbr ?? standardAbbr;

    const abbr = now.isDST() ? dstAbbr : standardAbbr;

    const standardAltName = tzStrings?.[0]?.standard?.name ?? '';
    const dstAltName = tzStrings?.[0]?.daylight?.name ?? standardAltName;

    const altName = now.isDST() ? dstAltName : standardAltName;

    const min = tz.current.offset * 60;
    const hr = `${Math.round(min / 60)}:` + (min % 60 === 0 ? '00' : Math.abs(min % 60));
    const prefix = `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${zone[1]}`;

    const label = prefix;

    return {
      value: zone[0],
      label,
      offset: tz.current.offset ?? 0,
      abbrev: abbr,
      altName,
    };
  } catch {
    return null;
  }
};

export function useTimezones(defaultTz?: null | string): CategoryItem[] {
  const options = React.useMemo(() => {
    const timezones = allTimezones as Record<string, string>;
    if (defaultTz && !timezones[defaultTz]) {
      timezones[defaultTz] = defaultTz;
    }
    return (
      Object.entries(timezones)
        .map((zone) => parseTz(zone))
        .filter((item: null | ITimezoneOption) => !!item) as ITimezoneOption[]
    ).sort((a: ITimezoneOption, b: ITimezoneOption) => a.offset - b.offset);
  }, [defaultTz, allTimezones]);
  // if (defaultTz && !options.find(({ value }) => defaultTz === value)) {
  //   const parsed = parseTz([defaultTz, defaultTz]);
  //   if (parsed) {
  //     options.push(parsed);
  //     options.sort((a: ITimezoneOption, b: ITimezoneOption) => a.offset - b.offset);
  //   }
  // }
  return options.map(({ value, label }) => ({ id: value, name: label }));
}
