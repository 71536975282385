import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@mui/styles';
import Menu, { MenuProps } from '@mui/material/Menu';

export const DarkStyledMenuItem = withStyles((theme) => ({
  root: {
    lineHeight: '42px',
    backgroundColor: 'white',
    border: `1px solid ${theme.custom.slate}`,
    borderBottom: '0 none',
    '&:focus': {
      backgroundColor: 'white',
    },
    '&.Mui-selected:hover,&:hover,&.Mui-selected': {
      color: theme.custom.white,
      backgroundColor: theme.custom.slate,
    },
    '&:last-child': {
      borderBottom: `1px solid ${theme.custom.slate}`,
    },
  },
}))(MenuItem);

function DarkMenu(props: MenuProps): React.ReactElement {
  return (
    <Menu
      MenuListProps={{
        style: {
          padding: 0,
        },
      }}
      PaperProps={{
        style: {
          width: 250,
          boxShadow: 'none',
          borderRadius: '0',
        },
      }}
      {...props}
    >
      {props.children}
    </Menu>
  );
}

export default DarkMenu;
