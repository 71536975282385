import { stepIconClasses } from '@mui/material/StepIcon';
import custom from '../custom';

export default {
  styleOverrides: {
    root: {
      color: custom.lightGrayDark,
      fontSize: 'inherit',
      borderWidth: 0,
      borderStyle: 'solid',
      borderColor: custom.lightGrayDark,
      borderRadius: '50%',
      '&.Mui-active': {
        color: custom.slate,
        [`& .${stepIconClasses.text}`]: {
          fill: custom.white,
        },
      },
      '&.Mui-completed': {
        color: custom.lightGrayDark,
        borderColor: custom.lightGrayDark,
      },
    },
    text: {
      fill: custom.mediumGray,
      fontSize: 12,
      fontWeight: 700,
    },
  },
};
