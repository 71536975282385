import { StoreonModule } from 'storeon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const context: StoreonModule<Data, any> = (store) => {
  store.on('@init', () => ({ context: {} }));
  store.on('context/set', (_state, [id, context]) => ({
    ..._state,
    context: { ..._state.context, [id]: context },
  }));
};

export { context };
