import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import custom from '../custom';

export default {
  styleOverrides: {
    root: {
      color: custom.slate,
      borderRadius: 2,
      '&.Mui-disabled': {
        color: custom.slate,
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: custom.mediumGray,
        },
      },
      '&.Mui-error': {
        [`& .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: custom.orange,
        },
      },
      '& .rah-input-wrapper': {
        width: '100%',
      },
    },
    notchedOutline: {
      borderColor: custom.slate,
      '.error &': {
        borderColor: custom.errorRed,
      },
    },
    input: {
      boxSizing: 'border-box',
      fontSize: 16,
      padding: 16,
      height: 54,
      '&.Mui-disabled': {
        background: custom.lightGrayDark,
        WebkitTextFillColor: 'inherit',
      },
    },
    adornedEnd: {
      paddingRight: 5,
    },
  },
};
