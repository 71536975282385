import React from 'react';
import Button from '@mui/material/Button';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './HelpButton.module.scss';
import { HelpButtonProps } from './HelpButton.interface';
import cn from 'classnames';
import { ChatIcon } from '@theme/icons';

const HelpButton: React.FC<HelpButtonProps> = ({ status, staticText, variant = 'footer', onClick }) => (
  <Button
    className={cn({ [styles.helpButtonFooter]: variant === 'footer', [styles.helpButton]: variant === 'button' })}
    aria-label='Need help?'
    onClick={onClick}
    data-id='help-btn'
    variant={variant === 'button' ? 'outlined' : undefined}
  >
    {status === 'loading' ? (
      <CircularProgress className='icon' />
    ) : variant === 'footer' ? (
      <HelpOutlineIcon className='icon' />
    ) : (
      <ChatIcon className='icon' />
    )}
    {status === 'loading' && staticText.HelpLoading}
    {status !== 'loading' && staticText.footerHelpBtn}
  </Button>
);

export default HelpButton;
