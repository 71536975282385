import variables from '@theme/variables.module.scss';

export default {
  values: {
    xs: parseInt(variables.xs),
    sm: parseInt(variables.sm),
    md: parseInt(variables.md),
    lg: parseInt(variables.lg),
    xl: parseInt(variables.xl),
  },
};
