export default {
  styleOverrides: {
    root: {
      backgroundColor: 'rgba(0, 30, 51, 0.6)',
    },
    invisible: {
      backgroundColor: 'transparent',
    },
  },
};
