import { RefObject } from 'react';
import { useConfig } from 'hooks/useConfig';
import { usePlacesWidget } from 'react-google-autocomplete';

type ReturnData = {
  enabled: boolean;
  ref: RefObject<null>;
  autocompleteRef: RefObject<null>;
};

export const useAddressAutocomplete = (setValue: (name: string, value: string, config?: Data) => void): ReturnData => {
  const { configVar: mapsApiKey } = useConfig('google_maps_js_key');
  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: mapsApiKey!,
    onPlaceSelected: (place) => {
      if (!setValue) return;
      const address: { [key: string]: string } = {};
      const addressNameFormat: {
        [key: string]: { type: 'short_name' | 'long_name'; field?: string };
      } = {
        country: { type: 'short_name' },
        street_number: { type: 'short_name' },
        route: { field: 'line1', type: 'long_name' },
        locality: { field: 'city', type: 'long_name' },
        administrative_area_level_1: { field: 'state', type: 'short_name' },
        postal_code: { type: 'short_name' },
        postal_code_suffix: { type: 'short_name' },
      };
      for (const component of place.address_components) {
        const type: keyof typeof addressNameFormat = component.types[0];
        const map = addressNameFormat[type];
        if (map) {
          address[map.field || type] = component[map.type];
        }
      }
      const line1 = [address['street_number'], address['line1']].filter((v) => v).join(' ') || '';
      (ref.current! as { value: string }).value = line1;
      const nameMod = 'address.';
      const config = { shouldValidate: true, shouldDirty: true };
      setValue(`${nameMod}line1`, line1, line1 ? config : {});
      setValue(`${nameMod}city`, address['city'] || '', address['city'] ? config : {});
      setValue(`${nameMod}state`, address['state'] || '', address['state'] ? config : {});
      const postal_code = [address['postal_code'], address['postal_code_suffix']].filter((v) => v).join('-');
      setValue(`${nameMod}postal_code`, postal_code || '', postal_code ? config : {});
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    },
  });
  return {
    enabled: !!mapsApiKey,
    ref,
    autocompleteRef,
  };
};
