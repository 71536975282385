import React from 'react';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberProfileOutput } from 'services/api/client';
import gender from 'data/gender.json';
import honorific from 'data/honorific.json';
import suffix from 'data/suffix.json';
import $account from 'services/Account';
import { useTimezones } from 'hooks/useTimezones';
import dayjs from 'dayjs';
import FieldContainer from '@ui/atoms/FieldContainer/FieldContainer';
import FieldPseudo from '@ui/atoms/FieldPseudo/FieldPseudo';
import Row from '@ui/atoms/Row/Row';
import Col from '@ui/atoms/Col/Col';

export function ProfileFormInfo(): JSX.Element {
  const { data } = useMemberApi<MemberProfileOutput>('memberProfile');
  const timezones = useTimezones(data?.timezone);
  const { data: ranks } = useMemberApi<Data>('militaryRanks');
  return (
    <>
      <Row>
        <Col oneFourth>
          <FieldContainer label='Birthdate'>
            <FieldPseudo>{data?.dob ? dayjs(data.dob, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}</FieldPseudo>
          </FieldContainer>
        </Col>
        <Col oneFourth>
          <FieldContainer label='Honorific'>
            <FieldPseudo>{!!data?.honorific && honorific.find((item) => item.id === data.honorific)?.name}</FieldPseudo>
          </FieldContainer>
        </Col>
        <Col oneFourth>
          <FieldContainer label='Suffix'>
            <FieldPseudo>{!!data?.suffix && suffix.find((item) => item.id === data.suffix)?.name}</FieldPseudo>
          </FieldContainer>
        </Col>
        <Col oneFourth>
          <FieldContainer label='NATIONALITY'>
            <FieldPseudo>USA</FieldPseudo>
          </FieldContainer>
        </Col>
      </Row>
      <Row justifyStart>
        <Col oneFourth>
          <FieldContainer label='Time Zone'>
            <FieldPseudo>
              {data?.timezone ? timezones.find((zone) => data.timezone === zone.id)?.name : null}
            </FieldPseudo>
          </FieldContainer>
        </Col>
        {!$account.roleHhm() && (
          <>
            <Col oneFourth>
              <FieldContainer label='Rank'>
                <FieldPseudo>{!!data?.military.rank && !!ranks && ranks[data.military.rank]}</FieldPseudo>
              </FieldContainer>
            </Col>
          </>
        )}
        <Col oneFourth>
          <FieldContainer label='Gender'>
            <FieldPseudo>
              {!!data?.gender &&
                ('other' === data.gender ? data.gender_other : gender.find((item) => item.id === data.gender)?.name)}
            </FieldPseudo>
          </FieldContainer>
        </Col>
      </Row>
    </>
  );
}

export default ProfileFormInfo;
