import type { NextPage } from 'next';
import React from 'react';
import { AccountLayout } from '@components/Account/AccountLayout';
import { ProfileInfo, ProfileForm, ShippingForm } from 'views/account/profile';
import SectionDivider from '@ui/atoms/SectionDivider/SectionDivider';

const Profile: NextPage = () => {
  return (
    <AccountLayout title='Manage Account'>
      <>
        <ProfileInfo />
        <SectionDivider />
        <ProfileForm />
        <SectionDivider />
        <ShippingForm />
      </>
    </AccountLayout>
  );
};

export default Profile;
