import React from 'react';
import { Loader } from 'components/LoadingBlock';
import { usePromotion, useMrpPromotion, useUpsellPromotion } from 'hooks';

type Props = {
  children: React.ReactNode;
};

export default function GlobalWrapper({ children }: Props): JSX.Element {
  const { loaded: promoLoaded } = usePromotion();
  const { loaded: upsellLoaded } = useUpsellPromotion();
  const { loaded: mrpLoaded } = useMrpPromotion();
  return <div>{upsellLoaded && mrpLoaded && promoLoaded ? children : <Loader />}</div>;
}
