import { switchClasses } from '@mui/material/Switch';
import custom from '../custom';

export default {
  styleOverrides: {
    root: {
      width: 40,
      height: 24,
      padding: 0,
      display: 'flex',
      marginLeft: 7,
      marginRight: 10,
    },
    switchBase: {
      padding: 2,
      color: custom.white,
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: custom.white,
        [`& + .${switchClasses.track}`]: {
          opacity: 1,
          backgroundColor: custom.slate,
        },
      },
    },
    thumb: {
      width: 20,
      height: 20,
      boxShadow: 'none',
    },
    track: {
      borderRadius: 12,
      opacity: 1,
      backgroundColor: custom.mediumGray,
    },
    sizeSmall: {
      width: 24,
      height: 14,
      padding: 0,
      [`& .${switchClasses.switchBase}`]: {
        padding: 1,
        '&.Mui-checked': {
          transform: 'translateX(10px)',
        },
      },
      [`& .${switchClasses.thumb}`]: {
        width: 12,
        height: 12,
      },
      [`& .${switchClasses.track}`]: {
        borderRadius: 7,
        opacity: 1,
        backgroundColor: custom.mediumGray,
      },
    },
  },
};
