import custom from '../custom';

export default {
  styleOverrides: {
    root: {
      minHeight: 0,
    },
    indicator: {
      backgroundColor: custom.white,
      height: 1,
      borderLeftWidth: 1,
      borderLeftStyle: 'solid',
      borderLeftColor: custom.slate,
      borderRightWidth: 1,
      borderRightStyle: 'solid',
      borderRightColor: custom.slate,
    },
    flexContainer: {
      display: 'flex',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: custom.slate,
    },
    scroller: {
      display: 'block',
    },
  },
};
