import 'theme/MuiClassNameSetup';
import React, { useEffect } from 'react';
import AppshellContext from '@inc/AppshellContext';
import type { AppProps } from 'next/app';
import '@css/index.css';
import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '../theme';
import { CheckoutDetailsProvider } from 'context/checkoutDetails.context';
import { Loader } from 'components/LoadingBlock';
import { useConfig } from 'hooks/useConfig';
import { useMiddleware } from 'hooks/useMiddleware';
import * as Sentry from '@sentry/nextjs';
import GlobalWrapper from 'components/Global/GlobalWrapper';
import { StoreContext } from 'storeon/react';
import { store } from 'store';
import $account from 'services/Account';
import $container from 'services/container';
import $eventManager from 'services/EventManager';
import { AppshellWrapper, MemberLoadWrapper } from 'containers/Wrapper';
import '@css/osano.css';

declare global {
  interface Window {
    cloud_env: string;
  }
}

function MicroApp({ Component, pageProps }: AppProps): JSX.Element {
  const generateClassName = createGenerateClassName({
    seed: 'va',
  });
  const middleware = useMiddleware();
  const { isError, configVar } = useConfig('api_base_url');
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    $eventManager.init();
  }, []);
  if (!configVar) return <Loader />;
  return (
    <>
      {process.browser && !isError ? (
        <AppshellWrapper
          loader={<Loader />}
          isDev={'development' === process.env.NODE_ENV}
          middleware={middleware}
          name='checkout'
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          render={(appshell: any) => {
            const {
              uid,
              email,
              data: { memberId = null, firstname = null, lastname = null, roles = [] } = {},
            } = appshell?.user ?? {};
            if (uid && email) {
              Sentry.setUser({
                id: memberId ?? uid,
                memberId,
              });
            }
            window.cloud_env = process.env.NEXT_PUBLIC_APP_ENV!;
            $container.set('appshell', appshell);
            $account.setUserFromFb({ id: memberId, firstname, lastname, email, roles });
            const AppContent = (): JSX.Element => (
              <CheckoutDetailsProvider>
                <AppshellContext.Provider value={appshell}>
                  <GlobalWrapper>
                    <CssBaseline />
                    <Component {...pageProps} />
                  </GlobalWrapper>
                </AppshellContext.Provider>
              </CheckoutDetailsProvider>
            );
            return (
              <StylesProvider generateClassName={generateClassName}>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <StoreContext.Provider value={store}>
                      {uid || memberId ? (
                        <MemberLoadWrapper>
                          <AppContent />
                        </MemberLoadWrapper>
                      ) : (
                        <AppContent />
                      )}
                    </StoreContext.Provider>
                  </ThemeProvider>
                </StyledEngineProvider>
              </StylesProvider>
            );
          }}
        />
      ) : (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CheckoutDetailsProvider>
              <CssBaseline />
              <Component {...pageProps} />
            </CheckoutDetailsProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </>
  );
}

export default MicroApp;
