import { StoreonModule } from 'storeon';

const message: StoreonModule<Data, Data> = (store) => {
  store.on('@init', () => ({ message: { open: false } }));
  store.on('message/set', (_state, event) => ({ ..._state, message: event }));
  store.on('message/open', (_state, message) => ({
    ..._state,
    message: { open: true, type: 'success', message },
  }));
  store.on('message/error', (_state, message) => ({
    ..._state,
    message: { open: true, type: 'error', message },
  }));
  store.on('message/close', (_state) => ({
    ..._state,
    message: { open: false },
  }));
};

export { message };
