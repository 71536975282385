import { alertClasses } from '@mui/material/Alert';
import custom from '../custom';
import breakpoints from '../breakpoints';
import { alpha } from '@mui/system/colorManipulator';

export default {
  styleOverrides: {
    root: {
      position: 'relative',
      padding: '13px 16px',
      alignItems: 'center',
      borderRadius: 0,
      fontSize: 14,
      lineHeight: '22px',
      backgroundColor: custom.white,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      ['@media (min-width:' + breakpoints.values.lg + 'px)']: {
        fontSize: 16,
        lineHeight: '25px',
        padding: '12px 16px',
      },
    },
    standardSuccess: {
      backgroundColor: custom.white,
      color: custom.slate,
      [`& .${alertClasses.icon}`]: {
        color: 'inherit',
      },
      '&::before': {
        backgroundColor: alpha(String(custom.successGreen), 0.4),
      },
    },
    standardError: {
      backgroundColor: custom.white,
      color: custom.slate,
      [`& .${alertClasses.icon}`]: {
        color: 'inherit',
      },
      '&::before': {
        backgroundColor: alpha(String(custom.errorRed), 0.16),
      },
    },
    icon: {
      position: 'relative',
      opacity: 1,
      fontSize: 20,
      marginRight: 8,
      padding: 0,
    },
    message: {
      position: 'relative',
      padding: 0,
    },
    action: {
      position: 'relative',
      marginRight: -14,
      '& svg': {
        fontSize: 16,
      },
    },
  },
};
