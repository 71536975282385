import React, { useEffect } from 'react';
import { CompleteInfo, SelectPlan, ReviewDetails, SubscriptionDetails } from '../StepsContent';
import { PlanInterface, PromotionInterface } from 'api/userEnrollInfo';
import { ClassNameMap } from '@mui/styles/withStyles';
import { Elements } from '@stripe/react-stripe-js';
import Error from 'next/error';
import { stripeInstance } from 'utils';
import { UseCheckoutDetails } from '../../context/checkoutDetails.context';

type Props = {
  plans: PlanInterface[];
  ranks: Record<string, string>;
  branches: Record<string, string>;
  promotion: null | PromotionInterface;
  classes: ClassNameMap;
  activeStep: number;
  handleButtonNextStep: () => void;
  handleButtonPrevStep: () => void;
  handleOpenTravelProtectionDialog: () => void;
  handleOpenMembersDialog: () => void;
  handleOpenDonateDialog: () => void;
  handleOpenTrialDialog: () => void;
};

const steps = ['Select Plan', 'Review Details', 'Complete Information'];

export function TabPanelsWrapper({
  plans,
  promotion,
  ranks,
  branches,
  activeStep,
  classes,
  handleButtonNextStep,
  handleButtonPrevStep,
  handleOpenTravelProtectionDialog,
  handleOpenMembersDialog,
  handleOpenDonateDialog,
  handleOpenTrialDialog,
}: Props): JSX.Element {
  const { dispatch } = UseCheckoutDetails();

  useEffect(() => {
    dispatch({ type: 'S_STEP_VIEWED', payload: { title: steps[activeStep], step: activeStep + 1 } });
  }, [activeStep, dispatch]);

  switch (activeStep) {
    case 0: // Step 1
      return (
        <SelectPlan
          plans={plans}
          promotion={promotion}
          classes={classes}
          handleOpenMembersDialog={handleOpenMembersDialog}
          handleButtonNextStep={handleButtonNextStep}
          handleOpenTravelProtectionDialog={handleOpenTravelProtectionDialog}
          handleOpenTrialDialog={handleOpenTrialDialog}
        />
      );
    case 1: // Step 2
      return (
        <ReviewDetails
          classes={classes}
          handleButtonNextStep={handleButtonNextStep}
          handleButtonPrevStep={handleButtonPrevStep}
          handleOpenDonateDialog={handleOpenDonateDialog}
        />
      );
    case 2: // Step 3
      return (
        <Elements stripe={stripeInstance.stripePromise()}>
          <CompleteInfo ranks={ranks} classes={classes} handleButtonNextStep={handleButtonNextStep} />
        </Elements>
      );
    case 3: // Step 4
      return <SubscriptionDetails branches={branches} classes={classes} />;
    default:
      return <Error statusCode={404} />;
  }
}
