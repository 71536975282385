import custom from '../custom';

export default {
  styleOverrides: {
    root: {
      opacity: 1,
      flexGrow: 1,
      padding: '10px 15px',
      fontWeight: 400,
      backgroundColor: custom.lightGrayDark,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: custom.slate,
      borderBottom: 'none',
      textTransform: 'uppercase',
      fontSize: 12,
      lineHeight: 1.556,
      fontFamily: custom.akkuratMono,
      letterSpacing: '0.16em',
      minWidth: '0!important',
      minHeight: 0,
      maxWidth: 'none',
      marginLeft: 5,
      '&:first-child': {
        marginLeft: 0,
      },
      '&.Mui-selected': {
        backgroundColor: custom.white,
      },
    },
    textColorInherit: {
      opacity: 1,
    },
  },
};
