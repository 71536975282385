import custom from '../custom';

export default {
  styleOverrides: {
    label: {
      width: '100%',
      '& .link': {
        color: custom.facebookBlue,
        fontSize: 'inherit',
        padding: 0,
        minWidth: 'inherit',
        fontWeight: 'inherit',
        fontFamily: 'inherit',
        lineHeight: 'inherit',
        letterSpacing: 'inherit',
        verticalAlign: 'inherit',
      },
    },
  },
};
