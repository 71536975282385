import custom from '../custom';

export default {
  styleOverrides: {
    tooltip: {
      position: 'relative',
      backgroundColor: custom.slate,
      maxWidth: 236,
      borderRadius: 2,
      padding: 20,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 'inherit',
      boxShadow: '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);',
    },
    arrow: {
      color: custom.slate,
      fontSize: 12,
    },
  },
};
