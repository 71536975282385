import { authRef } from './Firebase';
import { redirectTo } from 'utils/appshell';
import middlewareHandler from './middleware/middleware';

export type User = {
  uid: string;
  email: string;
  displayName: string;
  data?: Data | null;
};

/**
 * Appshell handler class.
 */
class AppShellHandler {
  /**
   * Name of micro app.
   */
  name: string | null = null;

  /**
   * Context of micro app.
   */
  context: Data = {};

  /**
   * User data.
   */
  user: User | null = null;

  /**
   * The firebase.auth instance.
   */
  auth: Data = {};

  /**
   * Gets app name.
   */
  getName() {
    return this.name;
  }

  /**
   * Sets app name.
   */
  setName(name: string) {
    this.name = name;
  }

  /**
   * Gets app context.
   */
  getContext() {
    return this.context;
  }

  /**
   * Sets app context.
   */
  setContext(context: Data) {
    this.context = context;
  }

  /**
   * Allow client apps to access the firebase auth instance.
   */
  setAuth(auth: Data) {
    this.auth = auth;
  }

  /**
   * Returns firebase token for the logged in user.
   */
  async token() {
    return authRef.currentUser && (await authRef.currentUser.getIdToken(true));
  }

  /**
   * Processes a list of middleware items.
   */
  async processMiddleware(middleware: string[]) {
    await new middlewareHandler(this.name!, middleware, this.user).process();
  }

  /**
   * Redirects to the target site.
   */
  goto = (target: string, attributes?: Data, hash?: string) => redirectTo(target, attributes, hash);
}

export default new AppShellHandler();
