import React from 'react';
import Link from 'next/link';
import styles from './SidebarIcon.module.scss';
import { SidebarIconProps } from './SidebarIcon.interface';

export const SidebarIcon = ({ label, icon, appPath, routerAsPath, target, onClick }: SidebarIconProps): JSX.Element => (
  <Link href={appPath}>
    <a
      className={`${styles.link} ${routerAsPath == appPath ? styles.active + ' active' : ''} link`}
      onClick={onClick}
      target={target ?? undefined}
    >
      {React.cloneElement(icon, { className: styles.icon + ' icon' })}
      {label}
    </a>
  </Link>
);
