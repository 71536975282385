import custom from '../custom';

export default {
  styleOverrides: {
    root: {
      boxSizing: 'border-box',
      '.Mui-error &': {
        color: custom.orange,
      },
    },
    outlined: {
      minWidth: 100,
      borderRadius: 0,
      fontSize: 16,
      padding: '15px 14px',
      height: 53,
    },
    icon: {
      color: custom.slate,
    },
  },
};
