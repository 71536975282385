import custom from './custom';

const white = custom.white;
// const black = custom.black;

export default {
  primary: {
    contrastText: white,
    dark: custom.slate,
    main: custom.slate,
    light: custom.slate,
  },
  secondary: {
    contrastText: white,
    dark: custom.slate,
    main: custom.slate,
    light: custom.slate,
  },
  success: {
    contrastText: white,
    dark: custom.green,
    main: custom.green,
    light: custom.green,
  },
  info: {
    contrastText: white,
    dark: custom.blue,
    main: custom.blue,
    light: custom.blue,
  },
  warning: {
    contrastText: white,
    dark: custom.gold,
    main: custom.gold,
    light: custom.gold,
  },
  error: {
    contrastText: white,
    dark: custom.orange,
    main: custom.orange,
    light: custom.orange,
  },
  text: {
    primary: custom.slate,
    secondary: custom.slate,
    link: custom.slate,
  },
  background: {
    default: white,
    paper: white,
  },
  divider: custom.border,
};
