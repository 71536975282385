import { BaseAttributes, baseAttributes } from './helpers';

type OrderCompleted = BaseAttributes & {
  code?: string;
  discount?: number;
  householdAddonQuantity?: number;
  householdAddonRevenue?: number;
  promotionId?: number;
  title?: string;
  affiliation: string;
  orderId: string;
  paymentMethod: string;
  subLifecycle: string;
  currency: string;
  revenue: number;
  products: Array<{
    itemProductCategory: string;
    price: number;
    productId: string;
    quantity: number;
  }>;
  total: number;
  planRevenue: number;
  shipping: number;
  subtotal: number;
  tax: number;
  travelProtectionAddonQuantity?: number;
  travelProtectionAddonRevenue?: number;
  processingFeeQuantity: number;
  processingFeeRevenue: number;
  subscriptionPlan: string;
  quantity: number;
  marketing?: null | {
    utm_source?: null | string;
    utm_medium?: null | string;
    utm_campaign?: null | string;
    admitad_uid?: null | string;
  };
};

export default function orderCompleted(data: Data): Partial<OrderCompleted> {
  return {
    ...data,
    ...baseAttributes(),
  };
}
