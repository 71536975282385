import { createTheme } from '@mui/material/styles';
import custom from './custom';
import palette from './palette';
import paletteDark from './paletteDark';
import { Typography } from '@mui/material/styles/createTypography';
import typography from './typography';
import typographyDark from './typographyDark';
import { Breakpoints } from '@mui/system/createTheme/createBreakpoints';
import breakpoints from './breakpoints';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import components from './components';
import componentsDark from './componentsDark';

const theme = createTheme({
  custom,
  palette,
  typography: typography as Typography,
  breakpoints: breakpoints as Breakpoints,
  components: components as OverridesStyleRules,
});

export const themeDark = createTheme({
  custom,
  palette: paletteDark,
  typography: typographyDark as Typography,
  breakpoints: breakpoints as Breakpoints,
  components: componentsDark as OverridesStyleRules,
});

export default theme;
