import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { UseStyles } from 'hooks';
import { PurchaseBriefPromotionOutput } from 'services/api/client';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import { PromotionBannerBlock } from './PromotionBannerBlock';
import { ensureText, getConvertedPrice } from 'utils';

const DiscountIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 14 14' {...props}>
      <path d='M13.8292 6.58773L12.2501 5.00865V2.33348C12.2501 2.01148 11.9888 1.75015 11.6668 1.75015H8.99165L7.41256 0.171063C7.18448 -0.0570208 6.81581 -0.0570208 6.58773 0.171063L5.00865 1.75015H2.33348C2.01148 1.75015 1.75015 2.01148 1.75015 2.33348V5.00865L0.171063 6.58773C-0.0570208 6.81581 -0.0570208 7.18448 0.171063 7.41256L1.75015 8.99165V11.6668C1.75015 11.9888 2.01148 12.2501 2.33348 12.2501H5.00865L6.58773 13.8292C6.70148 13.943 6.85081 14.0001 7.00015 14.0001C7.14948 14.0001 7.29881 13.943 7.41256 13.8292L8.99165 12.2501H11.6668C11.9888 12.2501 12.2501 11.9888 12.2501 11.6668V8.99165L13.8292 7.41256C14.0573 7.18448 14.0573 6.81581 13.8292 6.58773ZM4.95848 4.08348C5.44148 4.08348 5.83348 4.47548 5.83348 4.95848C5.83348 5.44148 5.44148 5.83348 4.95848 5.83348C4.47548 5.83348 4.08348 5.44148 4.08348 4.95848C4.08348 4.47548 4.47548 4.08348 4.95848 4.08348ZM9.04181 9.91681C8.55881 9.91681 8.16681 9.52481 8.16681 9.04181C8.16681 8.55881 8.55881 8.16681 9.04181 8.16681C9.52481 8.16681 9.91681 8.55881 9.91681 9.04181C9.91681 9.52481 9.52481 9.91681 9.04181 9.91681ZM4.66681 10.1583L3.84198 9.33348L9.33348 3.84198L10.1583 4.66681L4.66681 10.1583Z' />
    </SvgIcon>
  );
};

type Props = {
  promotion: PurchaseBriefPromotionOutput | null;
};

export function PromotionBanner({ promotion }: Props): JSX.Element {
  const classes = UseStyles();
  const suffixes = {
    month: '/mo',
    year: '/yr',
  };
  const {
    state: { selectedPlan, promoId, promoCode, config },
    dispatch,
  } = UseCheckoutDetails();
  const { title, price, note, processing_fee, period, trial } = selectedPlan || {};
  useEffect(() => {
    dispatch({
      type: 'S_PROMOTION_APPLIED',
      payload: { name: promotion?.title, creative: '', description: '', promotionId: promoId, code: promoCode },
    });
  }, []);
  const fullPrice = period && period.number > 1;
  const featured = !fullPrice && 'year' === period?.unit && !trial;
  const multiplier = 'year' === period?.unit ? 12 : 1;
  const periodCount = period?.number || 1;
  const convertedAmount = price && getConvertedPrice(price.amount, featured, periodCount);
  const convertedOldPrice = 9.99 * multiplier;
  const convertedFee = processing_fee && processing_fee.discount && getConvertedPrice(processing_fee.discount);
  const suffix = period?.unit ? suffixes[period.unit as keyof typeof suffixes] : null;
  return (
    <div className={classes.promotion}>
      <PromotionBannerBlock banner={promotion?.banner || null} />
      <div className={classes.promotionInfo}>
        {!!promotion && (
          <div className={classes.promotionInfoLabel}>
            <DiscountIcon className='icon' /> Promotion
          </div>
        )}
        <div className={classes.promotionInfoPeriod}>
          {ensureText(title ?? '', true)}
          <span>{ensureText(promotion?.title ?? '', true)}</span>
        </div>
        {price && !!price.discount && !trial && !fullPrice && (
          <div className={classes.promotionInfoPriceOld}>
            ${convertedOldPrice}
            {suffix}
          </div>
        )}
        <div className={classes.promotionInfoPrice}>
          {convertedAmount}
          {!fullPrice && !trial && <span>{suffix}</span>}
        </div>
        <div className={classes.promotionInfoDescription}>
          {note && <p dangerouslySetInnerHTML={{ __html: ensureText(note, true) }} />}
          {!!processing_fee && !!processing_fee.discount && (
            <p className={classes.promotionFee}>+{convertedFee} Processing Fee</p>
          )}
        </div>
        {!!promotion && !config.promotion_info_hide_promotion_applied_cta && (
          <Button
            className={classes.promotionInfoButton}
            color='primary'
            variant='outlined'
            size='small'
            endIcon={<CheckCircleIcon />}
          >
            Promotion Applied
          </Button>
        )}
      </div>
    </div>
  );
}
