import React from 'react';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberProfileOutput } from 'services/api/client';
import { stateForces, states } from 'utils/helpers/helpers';
import FieldContainer from '@ui/atoms/FieldContainer/FieldContainer';
import FieldPseudo from '@ui/atoms/FieldPseudo/FieldPseudo';
import Row from '@ui/atoms/Row/Row';
import Col from '@ui/atoms/Col/Col';

export function ShippingFormInfo(): JSX.Element {
  const { data } = useMemberApi<MemberProfileOutput>('memberProfile');
  const type = data?.address?.type;
  const addressStates = 'home' === type ? states : stateForces;
  return (
    <>
      <Row>
        <Col oneHalf>
          <FieldContainer label='Address line 1'>
            <FieldPseudo>{data?.address?.line1}</FieldPseudo>
          </FieldContainer>
        </Col>
        <Col oneHalf>
          <FieldContainer label={'home' === type ? 'Address line 2' : 'Apt, floor, suite, etc.'}>
            <FieldPseudo>{data?.address?.line2}</FieldPseudo>
          </FieldContainer>
        </Col>
      </Row>
      <Row>
        <Col oneHalf>
          <FieldContainer label={'home' === type ? 'City' : 'APO/FPO/DPO'}>
            <FieldPseudo>{data?.address?.city}</FieldPseudo>
          </FieldContainer>
        </Col>
        <Col oneFourth>
          <FieldContainer label={'home' === type ? 'State' : 'Region'}>
            <FieldPseudo>
              {!!data?.address?.state && addressStates[data.address.state as keyof typeof addressStates]}
            </FieldPseudo>
          </FieldContainer>
        </Col>
        <Col oneFourth>
          <FieldContainer label='Zip Code'>
            <FieldPseudo>{data?.address?.postal_code}</FieldPseudo>
          </FieldContainer>
        </Col>
      </Row>
    </>
  );
}

export default ShippingFormInfo;
