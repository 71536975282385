import custom from '../custom';

export default {
  styleOverrides: {
    root: {
      boxSizing: 'border-box',
      '.Mui-error &': {
        color: custom.orange,
      },
    },
    outlined: {
      minWidth: 100,
      borderRadius: 0,
      fontSize: 16,
      padding: '16px',
      lineHeight: '22px',
    },
    filled: {
      lineHeight: '22px',
    },
    selectMenu: {
      lineHeight: '22px',
    },
    icon: {
      color: custom.slate,
    },
  },
};
