import Account from 'services/Account';

export type BaseAttributes = {
  context: {
    app: {
      build: string;
      name: string;
      namespace: string;
      version: string;
    };
  };
  source: string;
  masquerading: null | string;
};

export type BaseProductAttributes = {
  coupon?: string;
  discount?: string;
  productName: string;
  price: number;
  promotionId?: string;
  quantity: number;
  sku: string;
};

export const baseAttributes = (): BaseAttributes => {
  const source = 'CheckoutApp';
  return {
    context: {
      app: {
        build: process.env.NEXT_PUBLIC_APP_NAME_VERSION!,
        name: source,
        namespace: 'components/CheckoutForm',
        version: process.env.NEXT_PUBLIC_APP_VERSION!,
      },
    },
    source,
    masquerading: Account.getUser(true)?.id ?? null,
  };
};

export const baseProductAttributes = ({
  coupon,
  discount,
  productName,
  price,
  promotionId,
  sku,
}: Data): BaseProductAttributes => {
  return {
    coupon,
    discount,
    productName,
    price,
    promotionId,
    quantity: 1,
    sku,
  };
};
