import React, { useEffect } from 'react';
import clsx from 'clsx';
import MuiTextField from '@mui/material/TextField';
import { FormControl, FormHelperText } from '@mui/material';
import custom from 'theme/custom';
import { UseStyles } from 'hooks';
import { UseFormMethods } from 'react-hook-form';
import { CardElementComponent } from '@stripe/react-stripe-js';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import stripeJs from '@stripe/stripe-js';
import { UseCheckoutDetails } from '../../../context/checkoutDetails.context';

type Props = Partial<UseFormMethods> & {
  CardElement: CardElementComponent;
  errors: FieldErrors;
  register: () => void;
  clearErrors: () => void;
  noBorder?: boolean;
};

export function CreditCardBlock({ errors, register, CardElement, clearErrors, noBorder }: Props): JSX.Element | null {
  const classes = UseStyles();
  const { dispatch } = UseCheckoutDetails();

  useEffect(() => {
    return () => clearErrors('card_token');
  }, [clearErrors]);

  const onChangeHandler = (event: stripeJs.StripeCardElementChangeEvent) => {
    if (event.complete) dispatch({ type: 'S_PAYMENT_INFO_ENTERED', payload: { paymentMethod: event.brand, step: 2 } });
  };
  return (
    <>
      <div className={classes.fieldWrapper}>
        <label className={classes.fieldLabel}>Name on credit card</label>
        <MuiTextField
          fullWidth
          name='billing_name'
          variant='outlined'
          placeholder='John Smith'
          error={!!errors.billing_name}
          inputRef={register({ required: true })}
          className={clsx({
            [classes.fieldTextNoBorder]: !!noBorder,
          })}
          InputProps={{
            classes: {
              root: 'outlinedInputRoot',
              notchedOutline: 'outlinedInputNotchedOutline',
            },
          }}
        />
      </div>

      <FormControl error={!!errors.card_token} className={classes.fieldWrapper} fullWidth>
        <label className={classes.fieldLabel}>Credit card number</label>
        <CardElement
          className={clsx(classes.cardElement, {
            [classes.cardElementNoBorder]: !!noBorder,
          })}
          onFocus={() => {
            clearErrors('card_token');
          }}
          onChange={onChangeHandler}
          options={{
            hidePostalCode: true,
            iconStyle: 'solid',
            style: {
              base: {
                iconColor: custom.slate,
                color: custom.slate,
                '::placeholder': {
                  color: custom.lightText,
                },
              },
              invalid: {
                color: custom.orange,
              },
            },
          }}
        />
        {errors.card_token && <FormHelperText error>{errors.card_token.message}</FormHelperText>}
      </FormControl>
    </>
  );
}
