import custom from './custom';
import breakpoints from './breakpoints';

export default {
  fontFamily: custom.akkurat,
  h1: {
    color: custom.slate,
    fontFamily: custom.akkurat,
    fontWeight: 300,
    fontSize: '36px',
    lineHeight: 1.0834,
    letterSpacing: '-0.04em',
    ['@media (min-width:' + breakpoints.values.lg + 'px)']: {
      fontSize: '56px',
      lineHeight: 1.0715,
    },
  },
  h2: {
    color: custom.slate,
    fontFamily: custom.akkurat,
    fontWeight: 400,
    fontSize: '28px',
    letterSpacing: '-0.02em',
    lineHeight: 1.3125,
    ['@media (min-width:' + breakpoints.values.lg + 'px)']: {
      fontSize: '32px',
    },
  },
  h3: {
    color: custom.slate,
    fontFamily: custom.akkurat,
    fontWeight: 400,
    fontSize: '24px',
    letterSpacing: '-0.02em',
    lineHeight: 1.292,
  },
  h4: {
    color: custom.slate,
    fontFamily: custom.akkurat,
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '-0.02em',
    lineHeight: 1.5,
  },
  h5: {
    color: custom.slate,
    fontFamily: custom.akkurat,
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '-0.02em',
    lineHeight: 1.5,
  },
  h6: {
    color: custom.slate,
    fontFamily: custom.akkurat,
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '-0.02em',
    lineHeight: 1.5,
  },
  subtitle1: {
    color: custom.slate,
    fontFamily: custom.akkurat,
    fontSize: '16px',
    letterSpacing: '-0.02em',
    lineHeight: 1.5,
  },
  subtitle2: {
    color: custom.slate,
    fontFamily: custom.akkurat,
    fontSize: '16px',
    letterSpacing: '-0.02em',
    lineHeight: 1.5,
  },
  body1: {
    color: custom.slate,
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '1.5',
  },
  body2: {
    color: custom.slate,
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '1.5',
  },
  button: {
    fontFamily: custom.akkurat,
    color: custom.slate,
    fontSize: 18,
    lineHeight: 1.556,
    fontWeight: 700,
    letterSpacing: '0',
    textTransform: 'none',
  },
  caption: {
    color: custom.slate,
    fontSize: '11px',
    letterSpacing: '0.33px',
    lineHeight: '13px',
  },
  overline: {
    color: custom.slate,
    fontSize: '11px',
    fontWeight: 400,
    letterSpacing: '0.33px',
    lineHeight: '13px',
  },
};
