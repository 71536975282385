import React from 'react';
import styles from './SectionDivider.module.scss';
import cn from 'classnames';
import { SectionDividerProps } from './SectionDivider.interface';

const SectionDivider = ({
  hide,
  spacing = 'default',
  color = 'default',
  hideSpacing,
}: SectionDividerProps): JSX.Element => (
  <div
    className={cn(styles.SectionDivider, {
      [styles.hideDesktop]: hide === 'desktop',
      [styles.hideMobile]: hide === 'mobile',
      [styles.DesktopHidden]: hide === 'desktop-hidden',
      [styles.Hidden]: hide === 'hidden',
      [styles.spacingDefault]: spacing === 'default',
      [styles.spacingSmall]: spacing === 'small',
      [styles.spacingLarge]: spacing === 'large',
      [styles.spacingTiny]: spacing === 'tiny',
      [styles.spacingHideTop]: hideSpacing === 'top',
      [styles.spacingHideBottom]: hideSpacing === 'bottom',
      [styles.colorDefault]: color === 'default',
      [styles.colorGray12]: color === 'gray12',
    })}
  />
);

export default SectionDivider;
