import { useEffect, useState } from 'react';
import checkoutApi from 'services/api/checkout';
import Cookies from 'services/Cookies';
import { EnrollMrpOutput } from 'services/api/client';
import { ensureUrl } from 'utils';

type ReturnType = {
  loaded: boolean;
};

export const useMrpPromotion = (): ReturnType => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    (async () => {
      if (Cookies.get('mrp')) {
        try {
          const data = (await checkoutApi.mrpPromotionApply({
            data: Cookies.get('mrp')!,
          })) as unknown as EnrollMrpOutput;
          Cookies.remove('mrp');
          if (data?.redirect_url) {
            window.location.href = ensureUrl(data.redirect_url);
          }
          if (data?.mrp) {
            const urlParams = new URLSearchParams(window.location.search);
            if (!urlParams.get('mrp')) {
              urlParams.set('mrp', data.mrp);
              window.location.search = urlParams.toString();
            }
          }
        } catch (e) {
          /* continue regardless of error */
        }
      }
      setLoaded(true);
    })();
  }, []);
  return {
    loaded,
  };
};
