import Cookies from '../Cookies';
import resolvers from './resolvers';
import middlewares from './mws';
import { redirectTo } from 'utils/appshell';
import { User } from 'services/Appshell';

type Action = keyof typeof resolvers;
type Middleware = keyof typeof middlewares;

type ProcessedMiddleware = { action: Action } & Data;
type UnprocessedMiddleware =
  | string
  | {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resolver: any;
      reverse: boolean;
      type: string;
    };

type AppshellChain = {
  appName: string;
  middleware: string[];
};

type MiddlewareHelpers = {
  getNext: (action: string, currentIndex: number) => ProcessedMiddleware | undefined;
  getPrev: (action: string, currentIndex: number) => ProcessedMiddleware | undefined;
};

export type MiddlewareProps = {
  appName: string;
  config?: Data;
  index: number;
  helpers: MiddlewareHelpers;
  user: User | null;
};

export type ResolverProps = {
  appName: string;
  isDev: boolean;
  index: number;
  props: Data;
  helpers: MiddlewareHelpers;
  user: User | null;
};

/**
 * Middleware Handler Class.
 */
class middlewareHandler {
  /**
   * Name of the app.
   */
  private appName: string;

  /**
   * Name of the app.
   */
  private user: User | null;

  /**
   * A list of processed middleware callback outputs.
   */
  private middleware: ProcessedMiddleware[] = [];

  /**
   * A list of unprocessed middleware reserved callbacks.
   */
  private unprocessedMiddleware: UnprocessedMiddleware[] = [];

  /**
   * A flag identifies whether appshell runs in a dev mode.
   */
  private isDev: boolean = false;

  /**
   * Constructor of middleware handler class.
   */
  constructor(appName: string, middleware: UnprocessedMiddleware[], user: User | null, isDev = false) {
    this.user = user;
    this.appName = appName;
    this.unprocessedMiddleware = middleware;
    this.isDev = isDev;
  }

  /**
   * Returns a list of unprocessed middleware reserved callbacks.
   */
  getUnprocessed(): UnprocessedMiddleware[] {
    return this.unprocessedMiddleware;
  }

  /**
   * Gets next action item after the given one.
   */
  getNext(action: string, currentIndex: number): undefined | ProcessedMiddleware {
    for (let j = currentIndex + 1; j < this.middleware.length; j++) {
      if (action === this.middleware[j].action) {
        return this.middleware[j];
      }
    }
  }

  /**
   * Gets prev action item after the given one.
   */
  getPrev(action: string, currentIndex: number): undefined | ProcessedMiddleware {
    for (let j = 0; j < currentIndex; j++) {
      if (action === this.middleware[j].action) {
        return this.middleware[j];
      }
    }
  }

  /**
   * Returns a list of helper methods will be passed to resolvers.
   */
  getHelpers(): MiddlewareHelpers {
    return {
      getNext: this.getNext.bind(this),
      getPrev: this.getPrev.bind(this),
    };
  }

  /**
   * Runs resolver function.
   */
  runResolver(action: Action, index: number, args: Data): boolean {
    if (!resolvers[action]) {
      return false;
    }
    return resolvers[action]({
      appName: this.appName,
      isDev: this.isDev,
      index,
      props: args,
      helpers: this.getHelpers(),
      user: this.user,
    });
  }

  /**
   * Runs app middleware items.
   */
  async process(fromStore = false): Promise<undefined | boolean | number> {
    for (let i = 0; i < this.unprocessedMiddleware.length; i++) {
      const mId = this.unprocessedMiddleware[i] as Middleware;
      if (!middlewares[mId] && 'function' !== typeof middlewares[mId]) {
        continue;
      }
      const res = await middlewares[mId]({
        appName: this.appName,
        index: i,
        helpers: this.getHelpers(),
        user: this.user,
      });
      !!res && this.middleware.push(res as ProcessedMiddleware);
    }
    if (fromStore) {
      return this.middleware.length ? this.middleware.unshift() : false;
    }
    if (this.middleware.length) {
      Cookies.set(
        'appshell.chain',
        JSON.stringify({
          appName: this.appName,
          middleware: this.unprocessedMiddleware.filter((item) => 'string' === typeof item),
        })
      );
      let processed = false;
      this.middleware.forEach((item: ProcessedMiddleware, index: number) => {
        if (!processed && item && item.action) {
          const { action, ...args } = item;
          const ret = this.runResolver(action, index, args);
          if (ret) {
            processed = ret;
          }
        }
      });
      return true;
    } else {
      let chain: AppshellChain | null = null;
      try {
        chain = JSON.parse(Cookies.get('appshell.chain') || '');
      } catch (e) {
        /* continue regardless of error */
      }
      if (chain) {
        const { appName, middleware } = chain;
        if (this.appName === appName) {
          Cookies.remove('appshell.chain');
          return;
        }
        const ret = await new middlewareHandler(this.appName, middleware, this.user).process(true);
        if (!ret) {
          Cookies.remove('appshell.chain');
          redirectTo(appName);
        }
      }
    }
  }
}

export default middlewareHandler;
