import React from 'react';
import FieldWrapper from './FieldWrapper';
import { Controller, useFormContext } from 'react-hook-form';
import { UseStyles } from 'hooks';
import InputAdornment from '@mui/material/InputAdornment';
import { CalendarNewIcon } from 'theme/icons';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField as MuiDateField, DateFieldProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
  description?: React.ReactNode | string;
  hidden?: boolean;
  label?: React.ReactNode | string;
  name: string;
  format?: string;
  formatFrom?: string;
  onChange?: (value: string) => void;
  required?: boolean;
} & DateFieldProps<Dayjs>;

function DateField({
  description,
  hidden,
  label,
  name,
  format = 'MM/DD/YYYY',
  formatFrom,
  onChange,
  required,
  defaultValue,
  ...props
}: Props): JSX.Element {
  const { control } = useFormContext();
  const classes = UseStyles(['accountProfileBirthdateIcon']);
  return (
    <FieldWrapper helperText={description} hidden={hidden} label={label} name={name} required={required}>
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        render={({ onChange: onChangeNative, value }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MuiDateField
              className='dateField'
              fullWidth
              format={format}
              value={value ? dayjs(value, formatFrom) : null}
              // minDate={dayjs('1920-01-01', 'YYYY-MM-DD')}
              // maxDate={dayjs().subtract(13, 'y')}
              onChange={(newValue, context) => {
                if (context.validationError === null && newValue) {
                  const value = newValue.format(formatFrom ?? format);
                  onChangeNative(value);
                  !!onChange && onChange(value);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <CalendarNewIcon className={classes.accountProfileBirthdateIcon} />
                  </InputAdornment>
                ),
              }}
              {...props}
            />
          </LocalizationProvider>
        )}
      />
    </FieldWrapper>
  );
}

export default DateField;
