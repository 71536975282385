import React, { Dispatch, PropsWithChildren, ReactNode, useMemo } from 'react';
import { PlanInterface, UserInterface, UserDataInterface, PreviewDataInterface } from 'api/userEnrollInfo';
import { initialState, CheckoutDetailsContextType, ReviewDetailsType } from './initialState';
import segmentDispatcher from 'services/segment/dispatchHandler';
import {
  PurchaseBriefOverviewOutputConfig,
  PurchaseBriefProductOutput,
  PurchaseBriefLineItemsGroupOutput,
  BrandQueueOutput,
} from 'services/api/client';

const CheckoutDetailsContext = React.createContext<ContextValues>({
  state: initialState,
  dispatch: () => ({ type: '', payload: {} }),
});

type PromoPayload = { promoId?: number; promoCode?: string };

export interface MemberTimelinesInterface {
  members: Array<{
    name: string;
    features: Array<{
      type: string;
      expires: null | number;
      autorenew: boolean;
    }>;
  }>;
  subscription_expires: null | number;
}

export type CheckoutDetailsActions =
  | { type: 'S_ENROLMENT_STARTED' }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | { type: 'S_PROMOTION_APPLIED'; payload: any }
  | { type: 'S_STEP_VIEWED'; payload: { title: string; step: number } }
  | { type: 'S_PAYMENT_INFO_ENTERED'; payload: { paymentMethod: string; step: number } }
  | { type: 'S_STEP_COMPLETED'; payload: { title: string; step: number } }
  | { type: 'S_HOUSEHOLD_MEMBERS_ADD'; payload: PurchaseBriefLineItemsGroupOutput & PromoPayload }
  | { type: 'S_HOUSEHOLD_MEMBERS_REMOVE'; payload: PurchaseBriefLineItemsGroupOutput & PromoPayload }
  | { type: 'SET_SUBSCRIPTION_DETAILS'; payload: UserDataInterface }
  | { type: 'SET_MEMBER_TIMELINES'; payload: MemberTimelinesInterface }
  | { type: 'SELECT_PLAN'; payload: PlanInterface & PromoPayload }
  | { type: 'TOGGLE_PRODUCT'; payload: { member_id: string; product_id: string } }
  | { type: 'SELECT_TRAVEL_PROTECTION'; payload: (PurchaseBriefProductOutput & PromoPayload) | undefined | null }
  | { type: 'SET_DETAILS'; payload: ReviewDetailsType }
  | { type: 'SET_USER'; payload: UserInterface }
  | { type: 'SET_PREVIEW_DETAILS'; payload: PreviewDataInterface }
  | { type: 'SET_CONFIG'; payload: PurchaseBriefOverviewOutputConfig }
  | { type: 'SET_BRAND_QUEUE'; payload: BrandQueueOutput | null }
  | { type: 'SET_PROMO_CODE'; payload: string }
  | { type: 'SET_PROMO_INFO'; payload: PromoPayload }
  | { type: 'SET_MEMBERS_TRAVEL_PROTECTION'; payload: string[] };

const interceptActions = (state: CheckoutDetailsContextType, action: CheckoutDetailsActions) => {
  segmentDispatcher(state, action);
  return checkoutDetailsReducer(state, action);
};

const checkoutDetailsReducer = (
  state: CheckoutDetailsContextType,
  action: CheckoutDetailsActions
): CheckoutDetailsContextType => {
  switch (action.type) {
    case 'TOGGLE_PRODUCT': {
      const { member_id, product_id } = action.payload;
      const products = state.selectedProducts[member_id] ?? [];
      return {
        ...state,
        selectedProducts: {
          ...state.selectedProducts,
          [action.payload.member_id]: products.includes(product_id)
            ? products.filter((id) => id !== product_id)
            : [...products, product_id],
        },
      };
    }
    case 'SELECT_PLAN':
      return {
        ...state,
        selectedPlan: action.payload,
      };
    case 'SET_DETAILS':
      return {
        ...state,
        details: action.payload,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SELECT_TRAVEL_PROTECTION':
      return {
        ...state,
        user: { ...state.user, travel_protection: action.payload },
      };
    case 'SET_MEMBERS_TRAVEL_PROTECTION':
      return {
        ...state,
        travelProtection: action.payload,
      };
    case 'SET_SUBSCRIPTION_DETAILS':
      return {
        ...state,
        subscriptionDetails: action.payload,
      };
    case 'SET_MEMBER_TIMELINES':
      return {
        ...state,
        memberTimelines: action.payload,
      };
    case 'SET_PREVIEW_DETAILS':
      return {
        ...state,
        previewDetails: action.payload,
      };
    case 'SET_CONFIG':
      return {
        ...state,
        config: action.payload,
      };
    case 'SET_BRAND_QUEUE':
      return {
        ...state,
        brand_queue: action.payload,
      };
    case 'S_PAYMENT_INFO_ENTERED':
      return {
        ...state,
        paymentDetails: action.payload,
      };
    case 'SET_PROMO_CODE':
      return {
        ...state,
        promoCode: action.payload,
      };
    case 'SET_PROMO_INFO':
      return {
        ...state,
        promoId: action.payload.promoId,
        promoCode: action.payload.promoCode,
      };
    default:
      return state;
  }
};

type CheckoutDetailsProviderProps = {
  children: ReactNode;
};

function CheckoutDetailsProvider({ children }: PropsWithChildren<CheckoutDetailsProviderProps>): JSX.Element {
  const [state, dispatch] = React.useReducer(interceptActions, initialState);

  const contextValues = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );

  return <CheckoutDetailsContext.Provider value={contextValues}>{children}</CheckoutDetailsContext.Provider>;
}

type ContextValues = {
  state: CheckoutDetailsContextType;
  dispatch: Dispatch<CheckoutDetailsActions>;
};

function UseCheckoutDetails(): ContextValues {
  return React.useContext(CheckoutDetailsContext);
}

export { CheckoutDetailsProvider, UseCheckoutDetails };
