import React, { useEffect } from 'react';
import { CardElementComponent } from '@stripe/react-stripe-js';
import { useFormContext } from 'react-hook-form';
import { PurchaseCardInfoOutput } from 'services/api/client';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import custom from 'theme/custom';
import stripeJs from '@stripe/stripe-js';
import PaymentMethods from '@ui/molecules/PaymentMethods/PaymentMethods';

type Props = {
  CardElement: CardElementComponent;
  setPayWithWallet: (status: boolean) => void;
  isCardChecked: boolean;
  idNoChargeChecked: boolean;
  isPayingWithWallet: boolean;
  enabledWalletMethod?: string;
  card: PurchaseCardInfoOutput | null | undefined;
};

export function PaymentOption({
  setPayWithWallet,
  CardElement,
  isCardChecked,
  idNoChargeChecked,
  isPayingWithWallet,
  enabledWalletMethod,
  card,
}: Props): JSX.Element {
  const { dispatch } = UseCheckoutDetails();
  const { errors, clearErrors } = useFormContext();
  useEffect(() => {
    if (idNoChargeChecked || isCardChecked || (enabledWalletMethod && isPayingWithWallet)) {
      clearErrors('card_token');
    }
  }, [idNoChargeChecked, isCardChecked, enabledWalletMethod, isPayingWithWallet]);
  const onChangeHandler = (event: stripeJs.StripeCardElementChangeEvent) => {
    if (event.complete) dispatch({ type: 'S_PAYMENT_INFO_ENTERED', payload: { paymentMethod: event.brand, step: 2 } });
  };

  return (
    <PaymentMethods
      PaymentCardComponent={(className) => (
        <CardElement
          className={className}
          onFocus={() => {
            clearErrors('card_token');
          }}
          onChange={onChangeHandler}
          options={{
            hidePostalCode: true,
            iconStyle: 'solid',
            style: {
              base: {
                iconColor: custom.slate,
                color: custom.slate,
                '::placeholder': {
                  color: custom.lightText,
                },
              },
              invalid: {
                color: custom.orange,
              },
            },
          }}
        />
      )}
      setPayWithWallet={setPayWithWallet}
      isCardChecked={isCardChecked}
      idNoChargeChecked={idNoChargeChecked}
      isPayingWithWallet={isPayingWithWallet}
      enabledWalletMethod={enabledWalletMethod}
      card={card}
      errors={errors}
    />
  );
}
