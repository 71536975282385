import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './PaymentCompleteInformationWrapper.module.scss';

const PaymentCompleteInformationWrapper: React.FC<PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> = ({
  children,
  className,
  ...props
}) => (
  <div {...props} className={cn(styles.PaymentCompleteInformationWrapper, className)}>
    {children}
  </div>
);

export default PaymentCompleteInformationWrapper;
