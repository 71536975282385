import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { FlagProvider, IConfig } from '@unleash/proxy-client-react';
import { Loader } from '@components/LoadingBlock';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberInfoOutput } from 'services/api/client';
import { BrokenUserDialog } from 'containers/Dialog/BrokenUserDialog';
import { Account, Segment } from 'services';

type Props = {
  children: React.ReactNode;
};

function MemberLoadWrapper({ children }: Props): JSX.Element {
  const [loaded, setLoaded] = useState(false);
  const { data, loading } = useMemberApi<MemberInfoOutput>('memberInfo');
  const config = {
    url: process.env.NEXT_PUBLIC_UNLEASH_URL,
    clientKey: process.env.NEXT_PUBLIC_UNLEASH_KEY,
    appName: 'account-app',
    context: { userId: data?.member_id },
    environment: process.env.NEXT_PUBLIC_APP_ENV,
  } as IConfig;

  useEffect(() => {
    if (!loaded && data && !loading) {
      const { first_name, last_name, email, roles = [], tier, member_id, masqueraded } = data;
      Segment.identify(member_id);
      Sentry.setUser({
        id: member_id,
        memberId: member_id,
      });
      Account.setUser(
        {
          id: member_id,
          firstname: first_name,
          lastname: last_name,
          email: email!,
          roles: [...roles, tier],
        },
        masqueraded
      );
      setLoaded(true);
    }
  }, [loading]);
  if (!loaded) {
    return (
      <>
        <Loader />
        <BrokenUserDialog />
      </>
    );
  }
  return (
    <FlagProvider config={config}>
      {children}
      <BrokenUserDialog />
    </FlagProvider>
  );
}

export default MemberLoadWrapper;
