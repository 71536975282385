import * as Sentry from '@sentry/nextjs';

class Unleash {
  /**
   * List of processed flags.
   */
  private flags: Record<string, Array<Record<string, Data>> | Data | undefined> = {};

  /**
   * List of promises based on memberId.
   */
  private promises: Record<string, Promise<Response>> = {};

  async load(memberId: string, cb: (data: Data) => Data) {
    if (this.flags && this.flags[memberId]) {
      return this.flags[memberId];
    }
    try {
      const env = process.env.NODE_ENV === 'development' ? 'dev' : 'prod';
      if (!this.promises[memberId]) {
        this.promises[memberId] = fetch(
          `${process.env.NEXT_PUBLIC_UNLEASH_URL}?environment=${env}&userId=${memberId}`,
          {
            headers: {
              Authorization: process.env.NEXT_PUBLIC_UNLEASH_KEY,
            } as HeadersInit,
          }
        );
      }
      const response = await this.promises[memberId];
      const data = (await response.clone().json()).toggles || [];
      this.flags[memberId] = cb ? cb(data) : data;
    } catch (e) {
      Sentry.captureException(e);
      this.flags[memberId] = undefined;
    }
    return this.flags[memberId];
  }
}

export default new Unleash();
