export default {
  styleOverrides: {
    root: {
      padding: 0,
      margin: 0,
      minHeight: 0,
      '&.Mui-expanded': { margin: 0, minHeight: 0 },
    },
    content: {
      margin: 0,
      '&.Mui-expanded': { margin: 0, minHeight: 0 },
    },
  },
};
