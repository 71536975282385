import React from 'react';
import Button from '@mui/material/Button';
import { UseStyles } from 'hooks';

export function UnauthenticatedLayout(): JSX.Element {
  const classes = UseStyles();
  return (
    <div className={classes.pageContentWrapper} style={{ textAlign: 'center' }}>
      <h4>To Sign In press button below</h4>
      <Button color='primary' variant='contained' size='small'>
        Sign In
      </Button>
    </div>
  );
}
