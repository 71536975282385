import React from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useStoreon } from 'storeon/react';
import { CheckIcon, WarningSignIcon } from 'theme/icons';

export default function Message(): JSX.Element {
  const {
    dispatch,
    message: { open, type, message },
  } = useStoreon('message');
  const handleClose = () => {
    dispatch('message/close');
  };
  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MuiAlert
        onClose={handleClose}
        severity={type as 'success' | 'error'}
        iconMapping={{
          success: <CheckIcon fontSize='inherit' />,
          error: <WarningSignIcon fontSize='inherit' />,
        }}
      >
        {message}
      </MuiAlert>
    </MuiSnackbar>
  );
}
