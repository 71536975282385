import React from 'react';
import styles from './UserName.module.scss';
import { VerificationStatusTrueIcon, VerificationStatusFalseIcon } from 'theme/icons';
import Typography from '@mui/material/Typography';
import { UserNameProps } from './UserName.interface';

export function UserName({ firstName, lastName, verified }: UserNameProps): JSX.Element {
  return (
    <Typography className={styles.accountSidebarWelcome} component='h3' variant='h3'>
      {firstName} {lastName}{' '}
      {verified ? <VerificationStatusTrueIcon className='icon' /> : <VerificationStatusFalseIcon className='icon' />}
    </Typography>
  );
}
