import React from 'react';
import styles from './SidebarLabelWithValue.module.scss';
import { Button } from '@mui/material';
import Link from 'next/link';
import { SidebarLabelWithValueProps } from './SidebarLabelWithValue.interface';

export function SidebarLabelWithValue({
  label,
  value,
  button,
  buttonLink,
  buttonOnClick,
  buttonAccess,
}: SidebarLabelWithValueProps): JSX.Element {
  return (
    <div className={styles.accountSidebarLabelWithValue}>
      <div className={styles.accountSidebarLabel}>{label}</div>
      <div className={styles.accountSidebarValue}>
        {value}
        {button && buttonAccess && (
          <>
            {!buttonOnClick ? (
              <Link href={buttonLink ?? '/'}>
                <Button className={styles.accountSidebarValueBtn}>{button}</Button>
              </Link>
            ) : (
              <Button className={styles.accountSidebarValueBtn} onClick={buttonOnClick}>
                {button}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
