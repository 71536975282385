import breakpoints from '../breakpoints';
import custom from '../custom';

export default {
  styleOverrides: {
    root: {
      '&.Mui-completed': {
        '&::before': {
          content: '""',
          display: 'block',
          width: 30,
          height: 30,
          borderRadius: '50%',
          backgroundColor: custom.mediumGray,
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          top: 1,
        },
      },
    },
    horizontal: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    alternativeLabel: {
      flex: 0,
      ['@media (min-width:' + breakpoints.values.lg + 'px)']: {
        flex: 1,
      },
    },
  },
};
