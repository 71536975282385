/* eslint-disable @typescript-eslint/no-empty-interface */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import withPathPrefix from 'utils/withPathPrefix';
import container from './container';
import Config from './Config';
import { ensureUrl } from 'utils';
import { isObject } from 'lodash';

declare module 'axios' {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interface AxiosResponse<T = any> extends Promise<T> {}
}

class HttpClient {
  private api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: withPathPrefix('/api'),
      withCredentials: true,
    });
    this.api.interceptors.request.use(async (config) => {
      const appshell = container.get('appshell');
      config.baseURL = ensureUrl(Config.getSync('api_base_url')) || withPathPrefix('/api');
      const authToken = appshell && appshell.token && (await appshell.token());
      if (authToken) {
        config.headers.common['authorization'] = `Bearer ${authToken}`;
      }
      return config;
    });

    this.api.interceptors.response.use(
      ({ data }) => (data?.data || isObject(data) ? data.data : data),
      (error) => Promise.reject(error.response)
    );
  }

  getApiClient() {
    return this.api;
  }

  async getBaseUrl() {
    return ensureUrl(await Config.get('api_base_url')) || withPathPrefix('/api');
  }

  async self<T = Data>(config: AxiosRequestConfig): Promise<T> {
    return await this.api(config);
  }

  async get<T = Data>(apiUrl: string): Promise<T> {
    return await this.self({
      baseURL: await this.getBaseUrl(),
      url: apiUrl,
    });
  }

  async post<T = Data, D = Data>(apiUrl: string, payload: D): Promise<T> {
    return await this.self({
      method: 'post',
      baseURL: await this.getBaseUrl(),
      url: apiUrl,
      data: payload,
    });
  }

  async put<T = Data>(apiUrl: string, payload: T): Promise<T> {
    return await this.self({
      method: 'put',
      baseURL: await this.getBaseUrl(),
      url: apiUrl,
      data: payload,
    });
  }

  async delete<T = Data>(apiUrl: string): Promise<T> {
    return await this.self({
      method: 'delete',
      baseURL: await this.getBaseUrl(),
      url: apiUrl,
    });
  }
}

export default new HttpClient();
