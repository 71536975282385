import React, { PropsWithChildren } from 'react';
import styles from './Row.module.scss';
import { RowProps } from './Row.interface';
import cn from 'classnames';

const Row = ({ children, className, justifyStart }: PropsWithChildren<RowProps>): JSX.Element => {
  return (
    <div
      className={cn(styles.row, className, {
        [styles.rowJustifyStart]: !!justifyStart,
      })}
    >
      {children}
    </div>
  );
};

export default Row;
