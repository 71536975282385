import { BaseAttributes, baseAttributes } from './helpers';

export type ctaClicked = BaseAttributes & {
  label: string;
  action: string;
  type: string;
  url: string;
};

export default function ctaClicked({ label, action, type, url }: Data): ctaClicked {
  return {
    ...baseAttributes(),
    label,
    action,
    type,
    url,
  };
}
