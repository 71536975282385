import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import TimelineDot from '@mui/lab/TimelineDot';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

const subStyles = makeStyles({
  blue: {
    backgroundColor: '#4287f5',
  },
  yellow: {
    backgroundColor: 'blue',
  },
});

type TimelineSection = {
  start: number;
  end: number;
  sections: Array<{
    time: number;
    purchase?: boolean;
    full?: boolean;
    subscription?: boolean;
  }>;
};

type ProductLine = {
  name: string;
  timeline: TimelineSection;
  autorenew?: boolean;
};

type UserProductsProps = {
  name: string;
  products: ProductLine[];
};

const Timeline = ({
  start,
  end,
  sections,
  forceColor,
  showDotTooltip,
  dotterLineHeight,
}: TimelineSection & { forceColor?: string; showDotTooltip?: boolean; dotterLineHeight?: number }): JSX.Element => {
  const classes = subStyles();
  const total = end - start;
  const time_list: number[] = [];
  sections = sections.filter(({ time }) => {
    // const result = !time_list.includes(time) && time > start && end >= (time + 1000 * 60 * 60 * 24);
    const result = !time_list.includes(time) && time > start && end >= time;
    time_list.push(time);
    return result;
  });
  return (
    <Box display='flex'>
      <Tooltip
        arrow
        title={new Date(start).toLocaleDateString('en-US')}
        placement={dotterLineHeight ? 'bottom' : 'top'}
        // {...(showDotTooltip ? { open: true } : {})}
      >
        <TimelineDot color='primary' variant='outlined' style={{ marginTop: -4 }} />
      </Tooltip>
      {sections.map(({ time, purchase, full, subscription }, index) => (
        <>
          <Tooltip
            title={new Date(time).toLocaleDateString('en-US')}
            placement='top'
            // {...(showTooltip ? { open: true } : {})}
          >
            <LinearProgress
              variant='determinate'
              // classes={forceColor ? { barColorPrimary: classes[forceColor as keyof typeof classes] } : {}}
              classes={
                subscription || forceColor
                  ? { barColorPrimary: classes[(forceColor ?? 'blue') as keyof typeof classes] }
                  : {}
              }
              color={purchase ? 'secondary' : 'primary'}
              value={full ? 100 : 0}
              style={{
                color: '#f00',
                width: `calc(${(100 * (time - (sections[index - 1]?.time ?? start))) / total}% - ${12}px)`,
                // width: "100%",
              }}
            />
          </Tooltip>
          <Tooltip
            arrow
            title={new Date(time).toLocaleDateString('en-US')}
            placement={
              dotterLineHeight ? (dotterLineHeight && sections.length > index + 1 ? 'bottom' : 'right') : 'top'
            }
            {...(showDotTooltip ? { open: true } : {})}
          >
            <TimelineDot color='primary' variant='outlined' style={{ marginTop: -4, position: 'relative' }}>
              {dotterLineHeight && sections.length > index + 1 ? (
                <div
                  style={{
                    borderLeft: '2px dotted #000',
                    height: dotterLineHeight,
                    left: 3,
                    bottom: 8,
                    position: 'absolute',
                  }}
                />
              ) : (
                <></>
              )}
            </TimelineDot>
          </Tooltip>
        </>
      ))}
    </Box>
  );
};

export const SubscriptionLine = ({
  timeline,
  members_count = 1,
}: ProductLine & { members_count?: number }): JSX.Element => {
  return (
    <ListItem>
      <ListItemText
        primary='Subscription'
        style={{
          width: '10%',
          textAlign: 'center',
          marginTop: -8,
          fontWeight: 'bold',
          fontSize: '16px',
        }}
      />
      <ListItemText
        primary={
          <Timeline
            start={timeline.start}
            end={timeline.end}
            sections={timeline.sections}
            forceColor='blue'
            showDotTooltip
            dotterLineHeight={135 * members_count}
          />
        }
        style={{ width: '90%' }}
      />
    </ListItem>
  );
};

const ProductLine = ({ name, timeline, autorenew }: ProductLine): JSX.Element => {
  return (
    <ListItem>
      <ListItemText
        primary={<>{name.toUpperCase()}&nbsp;</>}
        style={{
          width: '10%',
          textAlign: 'center',
          marginTop: -8,
          fontWeight: 'bold',
          fontSize: '16px',
        }}
      />
      <ListItemText
        primary={
          <Timeline
            forceColor={autorenew ? 'blue' : undefined}
            start={timeline.start}
            end={timeline.end}
            sections={timeline.sections}
          />
        }
        style={{ width: '90%' }}
      />
    </ListItem>
  );
};

export const UserProducts = ({ name, products }: UserProductsProps): JSX.Element => {
  return (
    <>
      <ListItem>
        <ListItemText
          primary={<span style={{ fontSize: '20px', fontWeight: 'bold' }}>{name}</span>}
          style={{
            textAlign: 'center',
          }}
        />
      </ListItem>
      {products.map(({ name: _name, timeline, autorenew }) => (
        <ProductLine key={_name} autorenew={autorenew} name={_name} timeline={timeline} />
      ))}
    </>
  );
};
