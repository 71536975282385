import custom from '../custom';

export default {
  styleOverrides: {
    root: {
      padding: 0,
      margin: '24px 0',
      '.text-gray': {
        color: custom.darkGray,
        a: {
          textDecoration: 'none',
        },
        'a:hover': {
          textDecoration: 'underline',
        },
      },
      '.text-red': {
        color: custom.errorRed,
      },
      '.text-small': {
        fontSize: 14,
        fontWeight: 300,
      },
      a: {
        color: 'inherit',
        textDecoration: 'underline',
      },
      'a:hover': {
        textDecoration: 'none',
      },
    },
  },
};
