import { AxiosPromise } from 'axios';
import { SuccessResponse } from 'services/api/client';
import useSWR from 'swr';
import * as Sentry from '@sentry/nextjs';
import MemberApi from 'services/api/member';
import { useStoreon } from 'storeon/react';
import { MemberApi as MemberApiClass } from 'services/api/client';
import { generateUniqueId } from 'utils';

type ReturnType<T> = {
  data: T | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutate: any;
  error: boolean;
  loading: boolean;
};

const useSWRoptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const _useMemberApi = <T, TData = any>(
  method: (...args: TData[]) => AxiosPromise<SuccessResponse>,
  args: TData[] = []
): ReturnType<T> => {
  const { data, error, mutate, isValidating } = useSWR(
    method.name,
    () => method.call(MemberApi, ...args),
    useSWRoptions
  );
  if (error) {
    Sentry.captureException(error);
  }
  return {
    data: data as unknown as T,
    mutate,
    error: false,
    loading: isValidating,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMemberApi = <T, TData = any>(method: keyof MemberApiClass, args: TData[] = []): ReturnType<T> => {
  const { dispatch } = useStoreon();
  const _method = MemberApi[method] as (...args: TData[]) => AxiosPromise<SuccessResponse>;
  const { data, error, mutate, isValidating } = useSWR(
    method + generateUniqueId(args),
    () => _method.call(MemberApi, ...args),
    useSWRoptions
  );
  if (error) {
    const type = error.data?.error?.type;
    const errorTypes = ['internal.user.corrupted', 'access.user.subscription'];
    if (type && errorTypes.includes(type)) {
      dispatch('context/set', ['brokenUser', true]);
    }
    Sentry.captureException(error);
  }
  return {
    data: data as unknown as T,
    mutate,
    error: false,
    loading: isValidating,
  };
};
