import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

type Props = Partial<UseFormMethods> & {
  disabled?: boolean;
  required?: boolean;
  name: string;
  label: React.ReactNode;
  defaultValue?: boolean;
};

export function ControllerCheckbox({
  control,
  name,
  label,
  required,
  disabled = false,
  defaultValue = false,
}: Props): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      defaultValue={defaultValue}
      render={(props) => (
        <FormControlLabel
          control={
            <Checkbox
              {...props}
              checked={props.value}
              disabled={disabled}
              color='primary'
              onChange={(e) => props.onChange(e.target.checked)}
            />
          }
          label={label}
        />
      )}
    />
  );
}
