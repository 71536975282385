import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { UseStyles } from 'hooks';
import container from 'services/container';

export interface SimpleDialogProps {
  open: boolean;
  onClose?: () => void;
}

const SupportDialog = (props: SimpleDialogProps): JSX.Element => {
  const classes = UseStyles();
  const { open } = props;
  const redirectTo = (path: string) => () => {
    const appshell = container.get('appshell');
    if (appshell && appshell.goto) {
      appshell.goto(path);
    }
  };
  return (
    <Dialog
      scroll='body'
      open={open}
      fullWidth
      maxWidth={false}
      classes={{ paper: classes.dialog + ' ' + classes.travelProtectionDialog }}
      aria-labelledby='customized-dialog-title'
    >
      <div className={classes.dialogTitle} id='customized-dialog-title'>
        <Typography component='h2' variant='h2'>
          Error
        </Typography>
      </div>
      <DialogContent className={classes.dialogContent}>
        <Typography component='h4' variant='h4'>
          An error occurred during your checkout. Please contact our customer support team at{' '}
          <a href='tel:1-866-838-7392'>1-866-838-7392</a>, or in the chat at the bottom of your screen, to complete your
          purchase.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions} disableSpacing>
        <Button onClick={redirectTo('platform/user/military-credentials/view')} color='primary' variant='outlined'>
          Back to My Account
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SupportDialog;
