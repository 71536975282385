import { AddressInterface } from 'api/userEnrollInfo';
import { AddressOutputTypeEnum } from 'services/api/client';

const defaultAddress = {
  type: AddressOutputTypeEnum.Home,
  line1: '',
  line2: '',
  postal_code: '',
  city: '',
  state: '',
};

const addressFields = ['type', 'line1', 'line2', 'postal_code', 'city', 'state'];

export type DefaultBillingAddressType = {
  billing_type: string;
  billing_line1: string;
  billing_line2: string;
  billing_postal_code: string;
  billing_city: string;
  billing_state: string;
};

const defaultBillingAddress = {
  billing_type: 'home',
  billing_line1: '',
  billing_line2: '',
  billing_postal_code: '',
  billing_city: '',
  billing_state: '',
};

export type AddressType = AddressInterface & DefaultBillingAddressType;

const setInitialAdresss = (
  userAddress: AddressInterface | null,
  defAddress: AddressInterface | DefaultBillingAddressType,
  isBilling = false
): Partial<AddressType> => {
  if (isBilling && userAddress) {
    return Object.keys(userAddress).reduce((prev, item) => {
      return { ...prev, [`billing_${item}`]: userAddress[item as keyof AddressInterface] };
    }, {});
  }
  return userAddress ? { ...userAddress } : defAddress;
};

const handleAddressFieldsMod = (type: 'home' | 'post', data: AddressType): AddressInterface => {
  const fields = 'home' === type ? addressFields : addressFields.map((field) => `billing_${field}`);
  return fields.reduce((prev, item) => {
    const modItem = item.includes('billing') ? item.replace(/billing_/, '') : item;
    return { ...prev, [`${modItem}`]: data[`${item}` as keyof AddressInterface] || null };
  }, {} as AddressInterface);
};

export { defaultAddress, defaultBillingAddress, setInitialAdresss, handleAddressFieldsMod };
