import React, { PropsWithChildren } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from './AccountDialog.module.scss';
import { AccountDialogProps } from './AccountDialog.interface';
import { useDialog } from 'hooks/useDialog';
import cn from 'classnames';

const AccountDialog: React.FC<PropsWithChildren<AccountDialogProps>> = ({
  name,
  title,
  subTitle,
  children,
  actions,
  actionsRow,
}) => {
  const { isOpen, close } = useDialog(name);
  return (
    <Dialog
      scroll='body'
      open={isOpen}
      onClose={close}
      fullWidth
      maxWidth={false}
      classes={{ paper: styles.accountDialog }}
      aria-labelledby='customized-dialog-title'
    >
      <div className={styles.accountDialogHeader} id='customized-dialog-title'>
        <IconButton className={styles.accountDialogHeaderClose} onClick={close}>
          <CloseIcon className='icon' />
        </IconButton>
        <div className={styles.accountDialogTitle}>{title}</div>
      </div>
      <DialogContent className={styles.accountDialogContent}>
        {subTitle && <div className={styles.accountDialogContentSubTitle}>{subTitle}</div>}
        {children}
      </DialogContent>
      {actions && (
        <DialogActions
          className={cn(styles.accountDialogActions, {
            [styles.accountDialogActionsRow]: !!actionsRow,
          })}
          disableSpacing
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AccountDialog;
