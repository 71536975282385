import custom from '../custom';
import breakpoints from '../breakpoints';

export default {
  styleOverrides: {
    iconContainer: {
      fontSize: 32,
      position: 'relative',
    },
    label: {
      color: custom.slate,
      fontSize: 16,
      display: 'none',
      '&.Mui-active': {
        color: custom.slate,
        fontWeight: 400,
      },
      '&.Mui-completed': {
        color: custom.slate,
        fontWeight: 400,
      },
      ['@media (min-width:' + breakpoints.values.lg + 'px)']: {
        display: 'block',
      },
    },
  },
};
