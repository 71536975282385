import jsCookie from 'js-cookie';
import { hostTld } from 'utils';

const defaultAttributes = {
  path: '/',
  domain: `.${hostTld()}`,
  expires: 1 / 24,
  SameSite: 'none',
  secure: true,
};

export default {
  get: jsCookie.get,
  set: (name: string, value: string, attributes: Data = {}): string | undefined =>
    jsCookie.set(name, value, {
      ...defaultAttributes,
      ...attributes,
    }),
  remove: (name: string, attributes: Data = {}): void =>
    jsCookie.remove(name, {
      ...defaultAttributes,
      ...attributes,
    }),
};
