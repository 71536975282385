import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { UseStyles } from 'hooks';

type Props = {
  open: boolean;
  onClose?: (success?: boolean) => void;
  title?: string;
  children: React.ReactNode;
};

export function DialogDefault({ open, onClose, title, children }: Props): JSX.Element {
  const classes = UseStyles([
    'accountDialog',
    'accountDialogHeader',
    'accountDialogHeaderClose',
    'accountDialogTitle',
    'accountDialogContent',
  ]);
  return (
    <Dialog
      scroll='body'
      open={open}
      onClose={() => !!onClose && onClose()}
      fullWidth
      maxWidth={false}
      classes={{ paper: classes.accountDialog }}
      aria-labelledby='customized-dialog-title'
    >
      {!!title && (
        <div className={classes.accountDialogHeader} id='customized-dialog-title'>
          {!!onClose && (
            <IconButton className={classes.accountDialogHeaderClose} onClick={() => onClose()}>
              <CloseIcon className='icon' />
            </IconButton>
          )}
          <div className={classes.accountDialogTitle}>{title}</div>
        </div>
      )}
      <DialogContent className={classes.accountDialogContent}>{children}</DialogContent>
    </Dialog>
  );
}
