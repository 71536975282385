import React from 'react';
import { useStoreon } from 'storeon/react';
import { Kustomer } from 'containers/Widget/Kustomer';
import { DialogError } from 'components/Dialog/DialogError';

export function BrokenUserDialog(): JSX.Element {
  const { context } = useStoreon('context');
  return (
    <DialogError open={!!context.brokenUser} title={'There seems to be a problem'} subTitle={'Error: Broken Data'}>
      <Kustomer
        prefixButtonText='Please contact'
        buttonText='customer service'
        suffixButtonText='for assistance'
        className='kustomer_btn'
      />
    </DialogError>
  );
}
