/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Services from './';

/**
 * EventManager class.
 */
class EventManager {
  /**
   * Map list event name and its handlers.
   */
  private map: Data = {};

  /**
   * Gets a list of event handlers from the services and register them.
   */
  init() {
    Object.values(Services).forEach((service: any) => {
      const { eventHandlers = {} } = service;
      Object.keys(eventHandlers).forEach((eventName: string) => {
        this.register(eventName, eventHandlers[eventName]);
      });
    });
  }

  /**
   * Registers a subscriber's event handlers.
   */
  public register(eventName: string, callback: any) {
    if (!this.map[eventName]) {
      this.map[eventName] = [];
    }
    this.map[eventName].push(callback);
  }

  /**
   * Dispatches an event to all registered subscribers.
   */
  public async dispatch(event: string, ...args: any) {
    (this.map[event] || []).forEach((handler: any) => handler(...args));
  }
}

export default new EventManager();
