import React, { useState } from 'react';
import styles from './ProfileAvatar.module.scss';
import { ProfileAvatarProps } from './ProfileAvatar.interface';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';

const ProfileAvatar = ({
  profilePhotoUrl,
  editPhoto,
  getInputProps,
  uploadDesktopFiles,
  deletePhoto,
}: ProfileAvatarProps): JSX.Element => {
  const [editShown, setEditShown] = useState(false);

  return (
    <div className={styles.accountProfileAvatarWrapper}>
      <div
        className={styles.accountProfileAvatar}
        onMouseEnter={() => setEditShown(true)}
        onMouseLeave={() => setEditShown(false)}
      >
        {profilePhotoUrl ? (
          <img width='100%' src={profilePhotoUrl} className={styles.accountProfileAvatarImg} />
        ) : (
          <PersonIcon className={styles.accountProfileAvatarIcon} />
        )}
        {!!profilePhotoUrl && editShown && (
          <Button
            color='primary'
            fullWidth
            disableRipple
            onClick={editPhoto}
            className={styles.accountProfileAvatarBtnEdit}
          >
            Edit Photo
          </Button>
        )}
      </div>
      <div className={styles.accountProfileAvatarBtns}>
        {!!getInputProps && <input {...getInputProps()} />}
        <Button color='primary' fullWidth onClick={uploadDesktopFiles} size='small' variant='outlined'>
          {profilePhotoUrl ? 'Change Photo' : 'Upload Photo'}
        </Button>
        {!!profilePhotoUrl && (
          <Button fullWidth onClick={deletePhoto}>
            Delete Photo
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProfileAvatar;
