import { useEffect, useState } from 'react';
import checkoutApi from 'services/api/checkout';
import Cookies from 'services/Cookies';
import { EnrollUpsellOutput } from 'services/api/client';
import { ensureUrl } from 'utils';

type ReturnType = {
  loaded: boolean;
};

function urlsEqual(current_url: string, redirect_url: string) {
  const map = {
    '/user/add-extension': '/profile',
    '/user/upgrade/subscription': '/upgrade',
  };
  try {
    let parsed_url1 = new URL(redirect_url);
    const parsed_url2 = new URL(current_url);
    const pathname = map[parsed_url1.pathname as keyof typeof map];
    if (pathname) {
      const { host } = parsed_url2;
      const { protocol, search, hash } = parsed_url1;
      parsed_url1 = new URL(`${protocol}${host}${pathname}${search}${hash}`);
    }
    return (
      parsed_url1.protocol === parsed_url2.protocol &&
      parsed_url1.host === parsed_url2.host &&
      parsed_url1.pathname === parsed_url2.pathname &&
      parsed_url1.search === parsed_url2.search &&
      parsed_url1.hash === parsed_url2.hash
    );
  } catch (error) {
    return false;
  }
}

export const useUpsellPromotion = (): ReturnType => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    (async () => {
      if (Cookies.get('upsell')) {
        try {
          const data = (await checkoutApi.upsellPromotionApply({
            data: Cookies.get('upsell')!,
          })) as unknown as EnrollUpsellOutput;
          Cookies.remove('upsell');
          if (data?.redirect_url && !urlsEqual(window.location.href, data.redirect_url)) {
            window.location.href = ensureUrl(data.redirect_url);
          }
        } catch (e) {
          /* continue regardless of error */
        }
      }
      setLoaded(true);
    })();
  }, []);
  return {
    loaded,
  };
};
