import custom from '../custom';

export default {
  styleOverrides: {
    text: {
      textDecoration: 'underline',
      padding: '4px 0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    textSizeSmall: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 1.5,
      padding: 0,
      minWidth: 0,
      letterSpacing: 0,
    },
    root: {
      borderRadius: 2,
      padding: '15px 16px',
    },
    contained: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&.Mui-disabled': {
        backgroundColor: custom.mediumGray,
        color: custom.white,
      },
    },
    outlined: {
      padding: '14px 16px',
      backgroundColor: custom.white,
      '&:hover': {
        backgroundColor: custom.white,
      },
      '&.Mui-disabled': {
        borderColor: custom.mediumGray,
        color: custom.mediumGray,
      },
    },
    outlinedPrimary: {
      color: custom.slate,
      borderColor: custom.slate,
      '&:hover': {
        backgroundColor: custom.white,
      },
    },
    outlinedSecondary: {
      color: custom.slate,
      borderColor: custom.slate,
      '&:hover': {
        backgroundColor: custom.white,
      },
    },
    containedSizeSmall: {
      fontSize: 14,
      lineHeight: 1.4286,
      padding: '10px 16px',
    },
    outlinedSizeSmall: {
      fontSize: 14,
      lineHeight: 1.4286,
      padding: '9px 16px',
    },
  },
};
