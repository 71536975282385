const newTld = 'wesalute.com';
const oldTld = 'veteransadvantage.com';

const hostTld = (forceNew = false): null | string => {
  if (!process.browser || forceNew) {
    return newTld;
  }
  const parts = window.location.hostname.split('.');
  const tld = parts.slice(-2).join('.');
  return [newTld, oldTld].includes(tld) ? tld : newTld;
};

const ensureUrl = (url?: null | string): string => {
  if (!url || oldTld !== hostTld(true)) {
    return url ?? '';
  }
  return url.replace(newTld, oldTld);
};

const ensureText = (text: string, reverse = false): string => {
  const map = {
    'WeSalute (founded as Veterans Advantage)': 'Veterans Advantage',
    'WeSalute+': 'VetRewards',
    'WeSalute ID': 'MilVetID',
  };
  if (reverse === (oldTld === hostTld(true))) {
    return text;
  }
  for (let from of Object.keys(map)) {
    let to = map[from as keyof typeof map];
    if (reverse) {
      const temp = to;
      to = from;
      from = temp;
    }
    text = text.replace(new RegExp(from, 'g'), to);
  }
  return text;
};

const isNewTld = (): boolean => newTld === hostTld(true);

export { ensureText, ensureUrl, hostTld, isNewTld };
