export default {
  styleOverrides: {
    root: {
      marginRight: 10,
      marginLeft: -7,
      '&:last-child': {
        marginRight: 0,
      },
    },
    label: {
      fontSize: 'inherit',
      color: 'inherit',
      width: '100%',
      '&.Mui-disabled': {
        color: 'inherit',
      },
    },
  },
};
