import { useMemo } from 'react';
import { PlanInterface, UserInterface } from 'api/userEnrollInfo';
import { ReviewDetailsType } from 'context/initialState';
import { EnrollInput, MemberInfoOutput } from 'services/api/client';
import Account from 'services/Account';

type Props = {
  user: UserInterface;
  info?: MemberInfoOutput;
  details: ReviewDetailsType;
  selectedPlan: PlanInterface;
  selectedTp?: string[];
  noCharge?: boolean;
};

const setPayloadForUserEnroll = ({
  selectedPlan,
  user,
  details,
  selectedTp = [],
  info,
  noCharge,
}: Props): EnrollInput => {
  const { id, parentPlan, one_time_purchase_product } = selectedPlan;
  const otp = one_time_purchase_product && parentPlan;
  const travel_protection = user.travel_protection && user.travel_protection.id;
  const cancel_primary_tp = info?.travel_protection && !travel_protection;
  return {
    plan: otp && parentPlan ? parentPlan : id,
    subscription_extension: otp ? id : undefined,
    travel_protection,
    household_members: user.household_members
      .filter((item) => {
        if (item.active && !item.travel_protection && !travel_protection) {
          return false;
        }
        return (
          !item.active || cancel_primary_tp || (!item.new && !!item.travel_protection !== selectedTp.includes(item.id!))
        );
      })
      .map((item) =>
        !item.new
          ? {
              id: item.id,
              email: item.email ? item.email : null,
              ...(cancel_primary_tp || (item.travel_protection && !selectedTp.includes(item.id!))
                ? { cancel: 'travel_protection' }
                : { travel_protection: !!travel_protection && selectedTp.includes(item.id!) }),
            }
          : {
              ...item,
              id: null,
              email: item.email ? item.email : null,
              travel_protection: !!travel_protection && selectedTp.includes(item.id!),
            }
      ),
    contribution: Number(details.donate.donate_other || details.donate.donate) * 100,
    ...(cancel_primary_tp ? { cancel: 'travel_protection' } : {}),
    ...(Account.roleAdmin() && noCharge ? { admin: { no_charge: true } } : {}),
  };
};

export function UsePreviewPayload({
  selectedPlan,
  user,
  details,
  selectedTp = [],
  info,
  noCharge,
}: Props): EnrollInput {
  return useMemo(
    () => setPayloadForUserEnroll({ selectedPlan, user, details, selectedTp, info, noCharge }),
    [selectedPlan, user, details, selectedTp, noCharge]
  );
}
