import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { UseStyles } from 'hooks';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import { AddHouseholdMemberText } from './AddHouseholdMemberText';

type Props = {
  handleOpenMembersDialog: () => void;
};

export function HouseholdMemberBlock({ handleOpenMembersDialog }: Props): JSX.Element | null {
  const classes = UseStyles();

  const {
    state: { user, config, selectedPlan },
  } = UseCheckoutDetails();

  if (config.household_members_exclude) return null;

  return (
    <div className={`${classes.familyMembers} ${classes.box}`}>
      <Typography component='h3' variant='h3'>
        {config.hhm_block_title ? (
          config.hhm_block_title
        ) : (
          <>Add household members{!selectedPlan?.trial ? ' at 50% OFF' : ''}</>
        )}
      </Typography>
      {config.hhm_block_body ? (
        <p className={classes.familyMembersDescription} dangerouslySetInnerHTML={{ __html: config.hhm_block_body }} />
      ) : (
        <p className={classes.familyMembersDescription}>
          {!!selectedPlan?.type && <AddHouseholdMemberText planType={selectedPlan.type} />}
        </p>
      )}
      {!!user.household_members.length && !config.hhm_block_hide_added_hhm_info && (
        <Typography className={classes.familyMembersSubTitle} component='h4' variant='h4'>
          {user.household_members.length} household member{user.household_members.length > 1 ? 's' : ''} added
        </Typography>
      )}
      <Button onClick={handleOpenMembersDialog} className={classes.familyMembersBtn} color='primary' variant='outlined'>
        {config.hhm_block_add_hhm_cta_name ? config.hhm_block_add_hhm_cta_name : 'Add household members'}
      </Button>
    </div>
  );
}
