import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Controller, UseFormMethods } from 'react-hook-form';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type Props = Partial<UseFormMethods> & {
  name: string;
  onChange?: (...event: unknown[]) => void;
  selectItems: Record<string, string>;
};

export function StateRegionField({ name, selectItems, control, onChange: handleChange }: Props): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: true,
        validate: (value) => !!selectItems[value],
      }}
      render={({ onChange, name, ref, value }) => {
        return (
          <Select
            displayEmpty
            name={name}
            onChange={(e) => {
              onChange(e);
              if (handleChange) {
                handleChange(e);
              }
            }}
            variant='outlined'
            fullWidth
            IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
            inputRef={ref}
            value={value}
          >
            <MenuItem value='' disabled>
              --- Select ---
            </MenuItem>
            {Object.keys(selectItems).map((item) => (
              <MenuItem key={item} value={item}>
                {selectItems[item]}
              </MenuItem>
            ))}
          </Select>
        );
      }}
    />
  );
}
