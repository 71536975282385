import React, { useCallback, useState } from 'react';
import { useMemberApi } from 'hooks/useMemberApi';
import MemberApi from 'services/api/member';
import { MemberProfileOutput, MemberInfoOutput } from 'services/api/client';
import { useDropzone } from 'react-dropzone';
import { ProfilePhotoPopup } from './';
import { ConfirmDialog } from 'containers/Dialog/ConfirmDialog';
import { AssetObject } from './ProfilePhotoPopup';
import { CheckCircleIcon } from 'theme/icons';
import { useStoreon } from 'storeon/react';
import { Kustomer } from 'containers/Widget/Kustomer';
import withPathPrefix from 'utils/withPathPrefix';
import { useRouter } from 'next/router';
import Config from 'services/Config';
import { Account } from 'services';
import ProfileNameForm from './ProfileNameForm';
import { ensureUrl } from 'utils';
import ProfileInfoBlock from '@ui/molecules/ProfileInfoBlock/ProfileInfoBlock';
import FieldContainer from '@ui/atoms/FieldContainer/FieldContainer';
import Row from '@ui/atoms/Row/Row';
import Col from '@ui/atoms/Col/Col';
import ProfileAvatar from '@ui/molecules/ProfileAvatar/ProfileAvatar';
import { PageTitle } from '@ui/atoms/PageTitle/PageTitle';
import InfoHelp from '@ui/atoms/InfoHelp/InfoHelp';
import AlertBlock from '@ui/molecules/AlertBlock/AlertBlock';
import { AlertBlockVariant } from '@ui/molecules/AlertBlock/AlertBlock.enum';

export function ProfileInfo(): JSX.Element {
  const { dispatch } = useStoreon();
  const router = useRouter();
  const { data, mutate } = useMemberApi<MemberProfileOutput>('memberProfile');
  const { data: info } = useMemberApi<MemberInfoOutput>('memberInfo');
  const [image, setImage] = useState<AssetObject | null>(null);
  const handleClosePopup = (croppedUrl?: string) => {
    setImage(null);
    if (croppedUrl) {
      mutate({ ...data, profile_photo: croppedUrl }, false);
      dispatch('message/open', 'The profile photo has been updated.');
    }
  };
  const deletePhoto = async () => dispatch('dialog/open', { id: 'profilePhotoDelete' });
  const editPhoto = async () => setImage({});
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setImage({
        error: 'size',
      });
    }
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async () => {
        const img = new Image();
        img.onload = function () {
          if (img.width < 208 || img.height < 208) {
            setImage((image) => ({
              ...image,
              error: 'dimensions',
            }));
          }
        };
        img.src = reader.result as string;
        setImage({
          name: file.name,
          preview: reader.result as string,
        });
      };
      reader.readAsDataURL(file);
    });
  }, []);
  const {
    getRootProps,
    getInputProps,
    open: uploadDesktopFiles,
  } = useDropzone({
    accept: 'image/*',
    maxSize: 2097152,
    noClick: true,
    multiple: false,
    onDrop,
  });
  const alertAdminBlock = !!data && !data?.verified && info?.masqueraded && (
    <AlertBlock
      title='Please verify the user first'
      buttonText='Verify the user'
      paymentLink={`${process.env.NEXT_PUBLIC_ADMIN_APP_URL}/admin/users?uid=${info?.id}`}
      description='User must be verified before making changes to their plan.'
      variant={AlertBlockVariant.Warning}
    />
  );
  return (
    <>
      {alertAdminBlock}
      <Row>
        <Col oneFourth>
          <div {...getRootProps()}>
            <FieldContainer label='Profile Photo'>
              <ProfileAvatar
                profilePhotoUrl={
                  data?.profile_photo
                    ? (ensureUrl(Config.getSync('api_base_url')) || withPathPrefix('/api')) + data?.profile_photo
                    : undefined
                }
                editPhoto={editPhoto}
                getInputProps={getInputProps}
                uploadDesktopFiles={uploadDesktopFiles}
                deletePhoto={deletePhoto}
              />
            </FieldContainer>
          </div>
        </Col>
        <Col threeFourth>
          <PageTitle size='medium'>Personal Information</PageTitle>
          <ProfileNameForm />
          {!Account.roleAdmin() && (
            <InfoHelp>
              <Kustomer
                buttonText='contact us'
                prefixButtonText='Please'
                suffixButtonText="if you've had a name change"
              />
            </InfoHelp>
          )}
          <Row>
            <Col oneThird>
              <ProfileInfoBlock
                label='Phone number:'
                value={
                  <>
                    {data?.phone
                      ? data.phone.replace(/^\+?([0-9]{1})([0-9]{3})([0-9]{3})([0-9]+)$/, '+$1 ($2) $3 $4')
                      : '-'}
                    {data?.verification?.phone && <CheckCircleIcon className='icon' />}
                  </>
                }
                button='Change Phone'
                buttonOnClick={() => router.push('/login')}
              />
            </Col>
            <Col twoThird>
              <ProfileInfoBlock
                label='Email address:'
                value={
                  <>
                    {data?.email || '-'}
                    {data?.verification?.email && <CheckCircleIcon className='icon' />}
                  </>
                }
                button='Change Email'
                buttonOnClick={() => router.push('/login')}
              />
            </Col>
          </Row>
        </Col>
        <ProfilePhotoPopup image={image} onClose={handleClosePopup} uploadDesktopFiles={uploadDesktopFiles} />
        <ConfirmDialog
          name='profilePhotoDelete'
          title='Delete Profile Photo'
          description='Are you sure you want to delete the profile photo?'
          handle={async () => await MemberApi.memberProfilePhotoDelete()}
          onSuccess={async () => mutate({ ...data, profile_photo: null }, false)}
        />
      </Row>
    </>
  );
}

export default ProfileInfo;
