import { StoreonModule } from 'storeon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dialog: StoreonModule<Data, any> = (store) => {
  store.on('@init', () => ({ dialog: {} }));
  store.on('dialog/open', (_state, { id, context = {} }) => ({
    ..._state,
    dialog: {
      ..._state.dialog,
      [id]: { open: true, context },
    },
  }));
  store.on('dialog/close', (_state, { id }) => ({
    ..._state,
    dialog: {
      ..._state.dialog,
      [id]: null,
    },
  }));
};

export { dialog };
