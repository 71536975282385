import { baseAttributes } from './helpers';

export default function featureFlagEvaluated({ toggleName, toggleValue, userId }: Data): Data {
  return {
    ...baseAttributes(),
    toggleName,
    toggleValue,
    userId,
  };
}
