import React, { PropsWithChildren } from 'react';
import styles from './FieldLabel.module.scss';
import cn from 'classnames';

const FieldLabel: React.FC<PropsWithChildren<React.LabelHTMLAttributes<HTMLLabelElement>>> = ({
  className,
  ...restProps
}) => <label className={cn(className, styles.fieldLabel)} {...restProps} />;

export default FieldLabel;
