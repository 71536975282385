import { BaseAttributes, baseAttributes } from './helpers';

type PaymentInfoEntered = BaseAttributes & {
  paymentMethod: string;
  step: number;
};

export default function paymentInfoEntered({ paymentMethod, step }: Data): PaymentInfoEntered {
  return {
    ...baseAttributes(),
    paymentMethod,
    step,
  };
}
