import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import MuiRadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiRadio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import { UseStyles } from 'hooks';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import { Controller, useForm } from 'react-hook-form';
import { PurchaseBriefOverviewOutputConfig } from 'services/api/client';
import { PlanInterface, UserInterface } from 'api/userEnrollInfo';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberInfoOutput } from 'services/api/client';
import EditIcon from '@mui/icons-material/Edit';
import { AddTravelProtectionDialog } from 'views/account/checkout/AddTravelProtectionDialog';
import { useDialog } from 'hooks/useDialog';
import { useStoreon } from 'storeon/react';

type Props = {
  requiredError: boolean;
  handleOpenTravelProtectionDialog: () => void;
};

export function TravelProtectionBlock({ requiredError, handleOpenTravelProtectionDialog }: Props): JSX.Element | null {
  const classes = UseStyles();
  const { control, watch, setValue } = useForm();
  const { close } = useDialog('manageTravelProtection');
  const setCloseAddTravelProtectionDialogPopup = (primaryChecked?: boolean) => {
    close();
    if (!primaryChecked) {
      setValue('TravelProtection', 'no');
    }
  };
  const { dispatch: stereonDispatch } = useStoreon();
  const {
    state: { selectedPlan, config, user, promoId, promoCode, travelProtection: tpSelected },
    dispatch,
  } = UseCheckoutDetails();
  const { data } = useMemberApi<MemberInfoOutput>('memberInfo');
  const travelCheckbox = watch('TravelProtection', user.travel_protection?.id);
  const setLabel = (plan: PlanInterface, user: UserInterface, config: PurchaseBriefOverviewOutputConfig) => {
    return (
      <div className={classes.travelProtectionRadioEditWrapper}>
        <div className={classes.travelProtectionRadioEdit}>
          <div className={classes.travelProtectionRadioEditText}>Yes! Add WeSalute+ TravelProtection for $39.98/yr</div>
          {travelCheckbox === travelProtection.id && (
            <Button
              className={classes.travelProtectionRadioEditBtn}
              size='small'
              onClick={() => stereonDispatch('dialog/open', { id: 'manageTravelProtection' })}
            >
              <EditIcon fontSize='inherit' className='icon' />
              Edit
            </Button>
          )}
          <AddTravelProtectionDialog
            onClose={setCloseAddTravelProtectionDialogPopup}
            onOpen={() => {
              const tpId = selectedPlan?.travel_protection[0]?.id;
              const hhmTpSelected = user.household_members.some(
                (member) => member.new && tpSelected?.includes(member.id)
              );
              if (tpId && hhmTpSelected) {
                setValue('TravelProtection', tpId);
              }
            }}
          />
        </div>
        {config.travel_protection_price_line_text && (
          <b>
            (<span dangerouslySetInnerHTML={{ __html: config.travel_protection_price_line_text }} />)
          </b>
        )}
      </div>
    );
  };

  useEffect(() => {
    const payload =
      selectedPlan?.travel_protection &&
      (config.travel_protection_preselect
        ? selectedPlan.travel_protection[0]
        : 'no' === travelCheckbox
        ? null
        : selectedPlan.travel_protection.find((item) => item.id === travelCheckbox));

    dispatch({
      type: 'SELECT_TRAVEL_PROTECTION',
      payload: payload ? { ...payload, promoId, promoCode } : payload,
    });
  }, [dispatch, travelCheckbox, selectedPlan, config.travel_protection_preselect]);
  if (!selectedPlan || (config.travel_protection_disable && !config.travel_protection_preselect)) return null;
  const travelProtection = selectedPlan.travel_protection[0];
  const _default = user.travel_protection
    ? user.travel_protection.id
    : null === user.travel_protection
    ? 'no'
    : data?.travel_protection && travelProtection?.id
    ? travelProtection.id
    : null;
  // const force = !!data?.travel_protection && !!_default;
  return (
    <div
      className={`
        ${classes.travelProtection}
        ${classes.box}
        ${requiredError ? 'error' : ''}
        ${!selectedPlan.travel_protection.length ? 'disabled' : ''}
    `}
    >
      <Typography component='h3' variant='h3'>
        Add WeSalute+ TravelProtection for Greater Peace of Mind
      </Typography>
      <div className={classes.travelProtectionDescription}>
        <ul>
          <li>
            You will be covered with medical insurance and travel assistance services when traveling more than 100 miles
            from home for any reason, including business and vacation travel, or just visiting your Mom!
          </li>
          <li>Get TravelProtection for only $39.98 a year (paid upfront)</li>
        </ul>
      </div>

      {config.travel_protection_preselect ? (
        <div className={classes.preselectTravelBlock}>{setLabel(selectedPlan, user, config)}</div>
      ) : !!data && !!selectedPlan ? (
        <Controller
          name='TravelProtection'
          control={control}
          defaultValue={_default}
          as={
            <MuiRadioGroup className={classes.travelProtectionRadios} aria-label='TravelProtection'>
              {travelProtection?.id ? (
                <FormControlLabel
                  classes={{ root: classes.travelProtectionRadioWrapper, label: classes.travelProtectionRadioLabel }}
                  value={travelProtection.id}
                  disabled={config.travel_protection_disable}
                  control={<MuiRadio color='primary' className={classes.travelProtectionRadio} />}
                  label={setLabel(selectedPlan, user, config)}
                />
              ) : (
                <FormControlLabel
                  classes={{ root: classes.travelProtectionRadioWrapper, label: classes.travelProtectionRadioLabel }}
                  value={'disabled'}
                  disabled={true}
                  control={<MuiRadio color='primary' className={classes.travelProtectionRadio} />}
                  label={'Yes! Add WeSalute+ TravelProtection for $39.98/yr'}
                />
              )}
              <FormControlLabel
                value={'no'}
                disabled={config.travel_protection_disable || !travelProtection?.id}
                control={<MuiRadio color='primary' />}
                label='No thanks. '
              />
            </MuiRadioGroup>
          }
        />
      ) : null}
      {requiredError && <div className={classes.travelProtectionError}>Please make a Travel Protection selection</div>}
      <Button onClick={handleOpenTravelProtectionDialog} className={classes.travelProtectionBtn}>
        Learn More about WeSalute+ TravelProtection
      </Button>
    </div>
  );
}
