import HttpClient from 'services/HttpClient';

class Config {
  private variables: Record<string, string> | undefined;

  async load() {
    try {
      this.variables = await HttpClient.self({ url: `/next/config?${process.env.NEXT_PUBLIC_APP_BUILD_TIMESTAMP}` });
    } catch (e) {
      this.variables = undefined;
    }
  }

  async get(name: string) {
    if (!this.variables) {
      await this.load();
    }
    return this.getSync(name);
  }

  getSync(name: string) {
    return this.variables && this.variables[name] ? this.variables[name] : null;
  }
}

export default new Config();
