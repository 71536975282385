import breakpoints from '../breakpoints';

export default {
  styleOverrides: {
    root: {
      marginTop: 4,
    },
    alternativeLabel: {
      left: 'calc(-50% + 35px)',
      right: 'calc(50% + 35px)',
    },
    line: {
      borderColor: '#B3BBCA',
      display: 'none',
      ['@media (min-width:' + breakpoints.values.lg + 'px)']: {
        display: 'block',
      },
    },
  },
};
