import React from 'react';
import styles from './FieldWrapper.module.scss';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import cn from 'classnames';

const FieldWrapper: React.ComponentType<FormControlProps> = ({ className, ...restProps }) => (
  <FormControl className={cn(className, styles.fieldWrapper)} {...restProps} />
);

export default FieldWrapper;
