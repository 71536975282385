import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FieldWrapper from 'components/Form/FieldWrapper';
import MuiTextField from '@mui/material/TextField';
import { useAddressAutocomplete } from 'hooks';

type Props = {
  hidden?: boolean;
  label?: string;
  name: string;
  onHiddenChange?: (input: HTMLInputElement) => void;
};

export function AddressAutocomplete({ hidden, label, name, onHiddenChange }: Props): JSX.Element {
  const { setValue } = useFormContext();
  const { ref, enabled } = useAddressAutocomplete(setValue);
  useEffect(() => {
    if (onHiddenChange && ref.current) {
      onHiddenChange(ref.current!);
    }
  }, [hidden]);
  return (
    <FieldWrapper hidden={hidden} label={label} name={name}>
      <MuiTextField
        fullWidth
        inputRef={ref}
        name={name}
        onBlur={(e) => setValue(name, e.target.value, { shouldDirty: true })}
        onChange={(e) => setValue(name, e.target.value)}
        variant='outlined'
        style={{ display: !enabled ? 'none' : 'block' }}
      />
    </FieldWrapper>
  );
}
