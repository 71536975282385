export default {
  styleOverrides: {
    root: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      padding: '0 0 34px',
      margin: '0 0 34px',
      borderBottom: '1px solid #F0F1F4',
      '&:before': {
        display: 'none',
      },
      '&.Mui-expanded': { padding: '0 0 0', margin: '0 0 34px' },
    },
  },
};
