import { MiddlewareProps } from '../middleware';

export default async function loggedIn({ user, config = {} }: MiddlewareProps): Promise<undefined | Data> {
  const { reverse = false, resolver } = config;
  if (!reverse && (!user || !user.uid)) {
    return resolver
      ? resolver({ user })
      : {
          action: 'redirect',
          destination: 'auth',
        };
  }
}
