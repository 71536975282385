export default {
  styleOverrides: {
    root: {
      fontSize: 12,
      borderBottom: 'none',
    },
    caption: {
      fontSize: 'inherit',
    },
    selectLabel: {
      fontSize: 'inherit',
      marginBottom: 0,
    },
    displayedRows: {
      fontSize: 'inherit',
      marginBottom: 0,
    },
  },
};
