import { useEffect, useState } from 'react';
import Config from 'services/Config';

type ReturnTypeNextConfig = {
  isError: boolean;
  configVar: string | null;
};

export const useConfig = (name: string): ReturnTypeNextConfig => {
  const [isError, setIsError] = useState(false);
  const [configVar, setConfigVar] = useState<string | null>(Config.getSync(name));

  useEffect(() => {
    (async () => {
      const configName = await Config.get(name);
      if (configName) return setConfigVar(configName);
      setIsError(true);
    })();
  }, [name]);

  return {
    isError,
    configVar,
  };
};
