import React from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { UseStyles } from 'hooks';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { PadlockUnlockedIcon, AlarmIcon, StarIcon } from '../../theme/icons';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  handleButtonNextStep: () => void;
}

const TrialDialog = (props: SimpleDialogProps): JSX.Element => {
  const classes = UseStyles();
  const { open, onClose, handleButtonNextStep } = props;

  return (
    <Dialog
      scroll='body'
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={false}
      classes={{ paper: classes.dialog + ' ' + classes.trialDialog }}
      aria-labelledby='customized-dialog-title'
    >
      <div className={classes.dialogTitle} id='customized-dialog-title'>
        <IconButton className={classes.dialogTitleClose} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography component='h2' variant='h2'>
          How your trial works
        </Typography>
      </div>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.trialDialogMainGraph}>
          <div className={classes.trialDialogMainGraphTop}>
            <div className={classes.trialDialogMainGraphTitle}>
              <PadlockUnlockedIcon className='icon' />
              Today
            </div>
            <div className={classes.trialDialogMainGraphDescription}>
              Get full access to 375+ valuable offers and benefits like financial planning & prescription drug
              discounts.
            </div>
            <div className={classes.trialDialogMainGraphTitle}>
              <AlarmIcon className='icon' />
              In 23 Days
            </div>
            <div className={classes.trialDialogMainGraphDescription}>
              Get a reminder about when your trial will end.
            </div>
            <div className={classes.trialDialogMainGraphTitle}>
              <StarIcon className='icon' />
              In 30 Days
            </div>
          </div>
          <div className={classes.trialDialogMainGraphBottom}>
            <div className={classes.trialDialogMainGraphDescription}>
              You’ll be charged for a 12-month subscription plan, cancel anytime before.
            </div>
          </div>
        </div>
        <div className={classes.trialDialogInfo}>
          <div className={classes.trialDialogInfoTitle}>30-Day trial, then</div>
          <div className={classes.trialDialogInfoSubTitle}>$59.95 for the year!</div>
          <div className={classes.trialDialogInfoDescription}>+$4.99 annual processing fee</div>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions} disableSpacing>
        <Button onClick={onClose} color='primary' variant='outlined'>
          Go back to view all plans
        </Button>
        <Button
          onClick={() => {
            onClose();
            handleButtonNextStep();
          }}
          color='primary'
          variant='contained'
        >
          I got it!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrialDialog;
