import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import styles from './PaymentCompleteInformationBox.module.scss';

const PaymentCompleteInformationBox: React.FC<PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> = ({
  children,
  className,
  ...props
}) => (
  <div {...props} className={cn(styles.PaymentCompleteInformationBox, className)}>
    {children}
  </div>
);

export default PaymentCompleteInformationBox;
