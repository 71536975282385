import { useStoreon } from 'storeon/react';

export function useDialog(dialogName: string): Data {
  const { dispatch, dialog } = useStoreon('dialog');
  return {
    isOpen: !!dialog[dialogName]?.open,
    context: dialog[dialogName]?.context,
    open: (context: Data = {}) => dispatch('dialog/open', { id: dialogName, context }),
    close: () => {
      dispatch('dialog/close', { id: dialogName });
    },
  };
}
