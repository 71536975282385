import { BaseAttributes, BaseProductAttributes, baseAttributes, baseProductAttributes } from './helpers';

type ProductAddedAttributes = BaseAttributes & BaseProductAttributes & { totalCount: number };

export default function productAdded(data: Data): ProductAddedAttributes {
  return {
    ...baseAttributes(),
    ...baseProductAttributes(data),
    totalCount: 1,
  };
}
