const microAppKeys = [
  'auth',
  'account',
  'checkout',
  'helloworld',
  'marketing',
  'marketplace',
  'verify',
  'platform',
] as const;

const whitelistUrlParams = ['mrp', 'return_to', 'iframe', 'action'];

export const ENVS = {
  dev: 'dev',
  stage: 'stage',
  prod: 'prod',
  local: 'dev',
};

type microAppName = (typeof microAppKeys)[number];
type microApp = {
  [k: string]: {
    host: string;
    basepath: string;
  };
};
type microAppConfig = {
  [k: string]: {
    domainEnvs?: { [k: string]: string };
    domainPrefix?: string | ((env: string) => string);
    withBasePath?: boolean;
  };
};

const microAppsConfig: microAppConfig = {
  auth: {
    withBasePath: false,
  },
  marketing: {
    domainEnvs: {
      stage: 'stage',
    },
    domainPrefix: (env: string) => (ENVS.prod === env ? 'www' : 'b2c-website'),
    withBasePath: false,
  },
  platform: {
    domainEnvs: {
      stage: 'stage',
    },
    domainPrefix: (env: string) => (ENVS.prod === env ? 'www' : 'b2c-website'),
    withBasePath: false,
  },
  verify: {
    withBasePath: false,
  },
};

const newTld = 'wesalute.com';
const oldTld = 'veteransadvantage.com';

export const hostTld = (): null | string => {
  if (!process.browser) {
    return newTld;
  }
  const parts = window.location.hostname.split('.');
  const tld = parts.slice(-2).join('.');
  return [newTld, oldTld].includes(tld) ? tld : newTld;
};

export const createSessionId = (): string => {
  function chr4() {
    return Math.random().toString(16).slice(-4);
  }
  return chr4() + chr4() + '-' + chr4() + '-' + chr4() + '-' + chr4() + '-' + chr4() + chr4() + chr4();
};

export const getQueryStringParams = (query: string): Record<string, string> => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params: Data, param: string) => {
        const [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {};
};

export const getSearchParam = (param: string): string => {
  const queryParams = getQueryStringParams(window.location.search);
  return queryParams[param];
};

export const hasSearchParam = (param: string): string => {
  const queryParams = getQueryStringParams(window.location.search);
  return queryParams[param];
};

export const getCurrentUrl = (): string => {
  const url = new URL(window.location.pathname, window.location.origin);
  const urlParams = new URLSearchParams(window.location.search);
  for (const param of whitelistUrlParams) {
    urlParams.has(param) && url.searchParams.append(param, urlParams.get(param)!);
  }
  return url.toString();
};

export const getEnvironment = (): string => {
  // if (process.env.NEXT_PUBLIC_APP_ENV) {
  //   return process.env.NEXT_PUBLIC_APP_ENV;
  // }
  if (!process.browser) {
    return ENVS.dev;
  }
  const map = {
    local: ENVS.dev,
    dev: ENVS.dev,
    stg: ENVS.stage,
    stage: ENVS.stage,
    test: ENVS.stage,
  };
  const isDev = 'development' === process.env.NODE_ENV;
  const match =
    window.location.host
      // eslint-disable-next-line
      .match(new RegExp(`[^\.]+?(\-(${Object.keys(map).join('|')}))?\.(veteransadvantage|wesalute)\.com`)) || [];
  return match[2] ? map[match[2] as keyof typeof map] : isDev ? ENVS.dev : ENVS.prod;
};

export const getMicroApps = (): microApp => {
  const microApps: microApp = {};
  const { host, protocol } = window.location;
  const env = getEnvironment();
  const envs: { [k: string]: string } = { stage: 'stg' };
  const withBasePath = !!process.env.__NEXT_ROUTER_BASEPATH;
  microAppKeys.forEach((key) => {
    const config = microAppsConfig[key] ?? {};
    const _withBasePath = withBasePath && config.withBasePath !== false;
    const _envs = config.domainEnvs ?? envs;
    let _domainPrefix: string = key;
    if (config.domainPrefix) {
      if ('function' === typeof config.domainPrefix) {
        _domainPrefix = config.domainPrefix(env);
      } else {
        _domainPrefix = config.domainPrefix;
      }
    }
    const _host = _withBasePath
      ? host
      : `${_domainPrefix}${ENVS.prod !== env ? `-${_envs[env] ? _envs[env] : env}` : ''}.${hostTld()}`;
    microApps[key] = {
      host: `${protocol}//${_host}`,
      basepath: _withBasePath ? `/${key}` : '',
    };
  });
  return microApps;
};

export const processAppUrl = (url: string = ''): null | string => {
  const microApps = getMicroApps();
  const [site, path = ''] = url.split(/\/(.+)?/) as [microAppName, string];
  if (site && microApps[site]) {
    const { host, basepath } = microApps[site];
    const _path = '[current]' === path ? (basepath ? window.location.pathname.replace(basepath, '') : '') : '/' + path;
    return host + basepath + _path;
  }
  return null;
};

export const redirectTo = (target?: string, attributes: { [k: string]: string | null } = {}, hash?: string): void => {
  const urlTo = processAppUrl(target || getSearchParam('destination'));
  if (urlTo) {
    const params = Object.keys(attributes || {}).map((key) => `${key}=${attributes[key]}`);
    window.location.href = urlTo + (params.length ? '?' + params.join('&') : '') + (hash ? `#${hash}` : '');
  }
};
