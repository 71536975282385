import React from 'react';
import { useFormContext, get } from 'react-hook-form';
import FieldContainer from '@ui/atoms/FieldContainer/FieldContainer';

type Props = {
  children: React.ReactNode;
  description?: string | React.ReactNode;
  errorAltText?: string | React.ReactNode;
  helperText?: string | React.ReactNode;
  helperTextTooltip?: React.ReactNode | string;
  helperTextBottom?: React.ReactNode | string;
  hidden?: boolean;
  label?: React.ReactNode | string;
  name: string;
  required?: boolean;
  noMargin?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

function FieldWrapper({ name, ...props }: Props): JSX.Element {
  const { errors } = useFormContext();
  const loading = false;
  const error = get(errors, name);
  return <FieldContainer {...props} error={error} loading={loading} />;
}

export default FieldWrapper;
