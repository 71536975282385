import { loadStripe, Stripe } from '@stripe/stripe-js';
import Config from 'services/Config';

const stripeInstance = (function getStripePromise(): { stripePromise: () => Promise<Stripe | null> } {
  let stripe: Promise<Stripe | null>;

  async function getLoadStripe() {
    const stripe_api_key = await Config.get('stripe_api_key');
    return loadStripe(stripe_api_key as string);
  }

  return {
    stripePromise() {
      return stripe || (stripe = getLoadStripe());
    },
  };
})();

export { stripeInstance };
