import React from 'react';
import Typography from '@mui/material/Typography';
import { FormHelperText } from '@mui/material';
import Button from '@mui/material/Button';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { CreditCardIcon, GooglePayIcon, ApplePayIcon } from 'theme/icons';
import { Checkbox, TextField } from '@components/Form';
import styles from './PaymentMethods.module.scss';
import cn from 'classnames';
import { PaymentMethodsProps } from './PaymentMethods.interface';
import PaymentCompleteInformationWrapper from '@ui/atoms/PaymentCompleteInformationWrapper/PaymentCompleteInformationWrapper';
import PaymentCompleteInformationBox from '@ui/atoms/PaymentCompleteInformationBox/PaymentCompleteInformationBox';
import FieldWrapper from '@ui/atoms/FieldWrapper/FieldWrapper';
import FieldLabel from '@ui/atoms/FieldLabel/FieldLabel';

const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  card,
  idNoChargeChecked,
  enabledWalletMethod,
  PaymentCardComponent,
  isCardChecked,
  errors,
  isPayingWithWallet,
  setPayWithWallet,
}) => {
  return (
    <PaymentCompleteInformationWrapper>
      <PaymentCompleteInformationBox>
        <Typography component='h3' variant='h3'>
          Choose your payment method
        </Typography>
        {!idNoChargeChecked && card && (
          <Checkbox label={`Use the credit card on file ending in ${card.last4}`} name='cardCheck' />
        )}
        {!idNoChargeChecked && !isCardChecked && !enabledWalletMethod && (
          <>
            <TextField
              label='Name on credit card'
              name='billing_name'
              defaultValue=''
              fullWidth
              placeholder='John Smith'
              error={!!errors.billing_name}
              InputProps={{
                classes: {
                  root: 'outlinedInputRoot',
                  notchedOutline: 'outlinedInputNotchedOutline',
                },
              }}
            />
            <FieldWrapper error={!!errors.card_token} fullWidth>
              <FieldLabel>Credit card number</FieldLabel>
              {PaymentCardComponent(cn(styles.cardElement))}
              {errors.card_token && <FormHelperText error>{errors.card_token.message}</FormHelperText>}
            </FieldWrapper>
          </>
        )}
        {!idNoChargeChecked && !isCardChecked && !!enabledWalletMethod && (
          <div className={styles.completeInformationMethods}>
            <div
              className={cn(styles.completeInformationMethod, 'item-0', {
                [styles.completeInformationMethodActive]: !isPayingWithWallet,
              })}
            >
              <Button className={styles.completeInformationMethodBtn} onClick={() => setPayWithWallet(false)}>
                {!isPayingWithWallet ? (
                  <RadioButtonCheckedIcon className='mark' />
                ) : (
                  <RadioButtonUncheckedIcon className='mark' />
                )}
                <>
                  <CreditCardIcon className='icon' />
                  Credit or Debit Card
                </>
              </Button>
              {!isPayingWithWallet && (
                <div className={styles.completeInformationMethodContent}>
                  <TextField
                    className={styles.fieldTextNoBorder}
                    label='Name on credit card'
                    name='billing_name'
                    fullWidth
                    placeholder='John Smith'
                    error={!!errors.billing_name}
                    InputProps={{
                      classes: {
                        root: 'outlinedInputRoot',
                        notchedOutline: 'outlinedInputNotchedOutline',
                      },
                    }}
                  />
                  <FieldWrapper error={!!errors.card_token} fullWidth>
                    <FieldLabel>Credit card number</FieldLabel>
                    {PaymentCardComponent(cn(styles.cardElement, styles.cardElementNoBorder))}
                    {errors.card_token && <FormHelperText error>{errors.card_token.message}</FormHelperText>}
                  </FieldWrapper>
                </div>
              )}
            </div>
            <div
              className={cn(styles.completeInformationMethod, 'item-1', {
                [styles.completeInformationMethodActive]: isPayingWithWallet,
              })}
            >
              <Button className={styles.completeInformationMethodBtn} onClick={() => setPayWithWallet(true)}>
                {isPayingWithWallet ? (
                  <RadioButtonCheckedIcon className='mark' />
                ) : (
                  <RadioButtonUncheckedIcon className='mark' />
                )}
                {enabledWalletMethod === 'apple-pay' ? (
                  <ApplePayIcon className='applePayIcon' />
                ) : (
                  <GooglePayIcon className='googlePayIcon' />
                )}
              </Button>
              {isPayingWithWallet && (
                <div
                  className={cn(styles.completeInformationMethodContent, styles.completeInformationMethodContentItem1)}
                >
                  Another step will appear to securely submit your payment information.
                </div>
              )}
            </div>
          </div>
        )}
      </PaymentCompleteInformationBox>
    </PaymentCompleteInformationWrapper>
  );
};

export default PaymentMethods;
