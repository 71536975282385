import React from 'react';
import Dialog from '@mui/material/Dialog';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { UseStyles } from 'hooks';

type Props = {
  open: boolean;
  title?: string;
  subTitle?: string;
  children: React.ReactNode;
};

export function DialogError({ open, title, subTitle, children }: Props): JSX.Element {
  const classes = UseStyles();
  return (
    <Dialog
      scroll='body'
      open={open}
      fullWidth
      maxWidth={false}
      classes={{ paper: classes.dialogError }}
      aria-labelledby='customized-dialog-title'
    >
      <div className={classes.dialogErrorContainer}>
        <div className={classes.dialogErrorContent}>
          <div className={classes.dialogErrorIcon}>
            <ErrorOutlineIcon fontSize='inherit' />
          </div>
          <div className={classes.dialogErrorMessage}>
            {!!title && <div className={classes.dialogErrorTitle}>{title}</div>}
            {!!subTitle && <div className={classes.dialogErrorSubTitle}>{subTitle}</div>}
            <div className={classes.dialogErrorText}>{children}</div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
