import React from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { UseStyles } from 'hooks';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

const DonateDialog = (props: SimpleDialogProps): JSX.Element => {
  const classes = UseStyles();
  const { open, onClose } = props;

  return (
    <Dialog
      scroll='body'
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={false}
      classes={{ paper: classes.dialog + ' ' + classes.donateDialog }}
      aria-labelledby='customized-dialog-title'
    >
      <div className={classes.dialogTitle} id='customized-dialog-title'>
        <IconButton className={classes.dialogTitleClose} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography component='h2' variant='h2'>
          Gift WeSalute+
        </Typography>
      </div>
      <DialogContent className={classes.dialogContent}>
        <Typography component='h2' variant='h2' className={classes.dialogSubTitle}>
          Help us improve the lives of wounded veterans
        </Typography>
        Over 32,000 military veterans were wounded in Iraq alone, and they need our support.
        <br />
        <br />
        Help us improve the lives of those who have sacrificed so deeply for our country by giving today. The entirety
        of your contribution will be used to fund the gift of WeSalute+ for our wounded warriors and their caregivers.
        WeSalute (founded as Veterans Advantage) will match your pledge dollar for dollar.
        <div className={classes.donateBanner}>
          <div className={classes.donateBannerImage}></div>
          <div className={classes.donateBannerInfo}>
            <div className={classes.donateBannerInfoQuote}>
              “I want to say this program is a Godsend. It has helped me in more ways than I can tell you. I am proud to
              be a member.”
            </div>
            Jennifer Roy, US Army Reservist
            <br />
            Wounded Veteran
            <br />
            Member Since 2012
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions} disableSpacing>
        <Button autoFocus onClick={onClose} color='primary' variant='outlined'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DonateDialog;
