import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.NEXT_PUBLIC_FB_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FB_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FB_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FB_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FB_MESSAGING_SENDER_ID,
  });
}

const dbRef = firebase.firestore();
dbRef.settings({ experimentalForceLongPolling: true, merge: true });

const auth = firebase.auth;
const authRef = firebase.auth();
const persistenceNone = firebase.auth.Auth.Persistence.NONE;
const persistenceSession = firebase.auth.Auth.Persistence.SESSION;
const persistenceLocal = firebase.auth.Auth.Persistence.LOCAL;

export { auth, authRef, dbRef, persistenceNone, persistenceSession, persistenceLocal };
