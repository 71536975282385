import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { DiscountPrice } from './../../DiscountBlock/DiscountPrice';
import { UseStyles } from 'hooks';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import { getConvertedPrice } from 'utils';
import { EnrollPreviewOutputLines } from 'services/api/client';

type Props = {
  lines?: EnrollPreviewOutputLines;
  subtotal: number;
  handleButtonPrevStep: () => void;
};

export function ReviewHouseholdMembers({ subtotal, handleButtonPrevStep, lines }: Props): JSX.Element | null {
  const classes = UseStyles();
  const promoTitles = {
    '1year': '12 month discounted fee vs. monthly plan',
    '3year': '3 year discounted fee vs. monthly plan',
    '5year': '5 year discounted fee vs. monthly plan',
  };
  const {
    state: {
      previewDetails,
      user: { household_members },
      selectedPlan,
      config: { plan_extension_preselect_product },
      promoId,
    },
  } = UseCheckoutDetails();
  const hhmLines = (lines ?? previewDetails.enrollPreview.lines).household_members.filter(
    ({ items }) => !!items.length
  );
  if (!household_members.length || !hhmLines.length) return null;
  const { number, unit } = selectedPlan?.period ?? { number: 1, unit: 'year' };
  const multiplier = ('year' === unit ? 12 : 1) * number;
  const oldPrice = 499 * multiplier;
  const promoTitle = promoTitles[`${number}${unit}` as keyof typeof promoTitles];
  return (
    <div className={classes.reviewDetailsMember}>
      {hhmLines.map(({ user: { first_name, last_name }, items }, index) => {
        if (!items.length) {
          return null;
        }
        return (
          <div key={`${index}-${first_name}-${last_name}`}>
            <div className={`${classes.reviewDetail} ${classes.reviewDetailFirst}`}>
              <Typography component='h4' variant='h4'>
                {first_name} {last_name}
              </Typography>
              <span>Household member</span>
            </div>
            {items.map(({ title, price, type, discount_reason }) => {
              const amount = price.discount ? price.cost : price.amount;
              const saleType =
                !!promoId &&
                ['plan', 'subscription_extension'].includes(type) &&
                'promotional_discount' === discount_reason?.type;
              return (
                <React.Fragment key={`${title} + ${type}`}>
                  {(amount !== 0 || (!!promoId && !plan_extension_preselect_product)) && (
                    <div className={`${classes.reviewDetail}`}>
                      {saleType && promoTitle ? (
                        <span>{promoTitle}</span>
                      ) : (
                        <span>{'travel_protection' === type ? 'WeSalute+ Travel Protection' : title}</span>
                      )}
                      <div className={classes.membersOldPrice}>
                        {saleType && 'year' === selectedPlan?.type && amount < oldPrice ? (
                          <span>${oldPrice / 100}</span>
                        ) : null}
                        {getConvertedPrice(amount)}
                      </div>
                    </div>
                  )}
                  {<DiscountPrice price={price} reason={discount_reason} type={type} />}
                </React.Fragment>
              );
            })}
          </div>
        );
      })}
      <div className={classes.divider} />
      <div className={classes.reviewDetail}>
        <Typography component='h4' variant='h4'>
          Subtotal
        </Typography>
        <Typography component='h4' variant='h4'>
          {getConvertedPrice(subtotal)}
        </Typography>
      </div>
      <Button size='small' onClick={handleButtonPrevStep}>
        Edit Plan
      </Button>
    </div>
  );
}
