import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import { UsePreviewPayload, UseStyles } from 'hooks';
import { useForm } from 'react-hook-form';
import { HouseholdMemberFormFields, ActiveHouseholdMember, AddHouseholdMemberText } from 'components/HouseholdMembers';
import { UseUserEnrollPreview } from 'api/userEnrollInfo';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberInfoOutput } from 'services/api/client';
import { PurchaseHouseholdMemberNewInput } from 'services/api/client';
import InfoIcon from '@mui/icons-material/Info';
import { useStoreon } from 'storeon/react';
import { formatDateDiff } from 'utils';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

const defaultValues: Omit<PurchaseHouseholdMemberNewInput, 'relationship'> & { relationship?: string } = {
  relationship: '',
  first_name: '',
  last_name: '',
  email: '',
  above_13: true,
  travel_protection: false,
};

const MembersDialog = ({ open, onClose }: SimpleDialogProps): JSX.Element => {
  const classes = UseStyles();
  const { dispatch: storeonDispatch } = useStoreon();
  const {
    state: { user, details, selectedPlan, previewDetails, promoId, promoCode, travelProtection },
    dispatch,
  } = UseCheckoutDetails();
  const payloadPreview = UsePreviewPayload({
    details,
    selectedPlan: selectedPlan!,
    user,
    selectedTp: travelProtection,
  });
  const { triggerEnrollPreview } = UseUserEnrollPreview(payloadPreview);

  const { register, handleSubmit, reset, control, errors, trigger, setError, clearErrors, watch, formState } = useForm({
    defaultValues,
  });
  const isAbove13 = watch('above_13');

  const { first_name, last_name, relationship, email } = formState.dirtyFields;
  const formTouched = !!first_name && !!last_name && !!relationship;

  const [isAddingHousehold, setIsAddingHousehold] = useState(false);
  const [isValidatingEmail, setIsValidatingEmail] = useState(false);

  const handleAddHouseholdMembers = (type: string) => async (data: PurchaseHouseholdMemberNewInput) => {
    const hhmId = (100000 * Math.random()).toFixed(0);
    const household_members = [
      ...user.household_members,
      {
        ...data,
        email: data.email ? data.email : null,
        id: hhmId,
        new: true,
      },
    ];
    if (data.travel_protection) {
      dispatch({
        type: 'SET_MEMBERS_TRAVEL_PROTECTION',
        payload: [...(travelProtection ?? []), hhmId],
      });
    }
    setIsAddingHousehold(true);
    const payload = { ...user, household_members };
    dispatch({ type: 'SET_USER', payload });

    const updPayloadPreview = {
      ...payloadPreview,
      household_members: household_members
        .filter((item) => !item.active)
        .map((item) => ({
          ...item,
          email: item.email ? item.email : null,
          id: item.new ? null : item.id,
          travel_protection: false,
        })),
    };
    const resp = await triggerEnrollPreview(updPayloadPreview);

    if (resp) {
      const householdPayload = {
        email: data.email ? data.email : null,
        ...resp.lines.household_members[user.household_members.length ? user.household_members.length - 1 : 0],
      };
      dispatch({
        type: 'S_HOUSEHOLD_MEMBERS_ADD',
        payload: { ...householdPayload, promoId, promoCode },
      });
      if ('add-to-plan' === type) {
        onClose();
      }
    }
    setIsAddingHousehold(false);
    reset(defaultValues);
  };

  const handleRemoveHouseholdMember = (index: number) => {
    const hhmId = user.household_members[index]?.id;
    const payload = { ...user, household_members: user.household_members.filter((item, i) => i !== index && item) };
    dispatch({ type: 'SET_USER', payload });
    if (hhmId && travelProtection?.includes(hhmId)) {
      dispatch({
        type: 'SET_MEMBERS_TRAVEL_PROTECTION',
        // payload: travelProtection.splice(travelProtection.indexOf(hhmId), 1),
        payload: travelProtection.filter((item) => item !== hhmId),
      });
    }
    if (previewDetails.enrollPreview.lines.household_members[index]) {
      const householdPayload = {
        email: user.household_members[index].email,
        ...previewDetails.enrollPreview.lines.household_members[index],
      };
      dispatch({
        type: 'S_HOUSEHOLD_MEMBERS_REMOVE',
        payload: { ...householdPayload, promoId, promoCode },
      });
    }
  };

  const errorExists = !!Object.keys(errors).length;
  const formProcessing = isValidatingEmail || isAddingHousehold;
  const openTpDialogNext = user.household_members.some((member) => member.new && travelProtection?.includes(member.id));
  const [showMore, setShowMore] = useState(false);
  const { data } = useMemberApi<MemberInfoOutput>('memberInfo');
  const expiresIn = data?.plan_expires && formatDateDiff(data?.plan_expires);
  return (
    <Dialog
      scroll='body'
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={false}
      classes={{ paper: classes.dialog + ' ' + classes.membersDialog }}
      aria-labelledby='customized-dialog-title'
    >
      <div className={classes.dialogTitle} id='customized-dialog-title'>
        <IconButton className={classes.dialogTitleClose} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography component='h2' variant='h2'>
          Add household members{!selectedPlan?.trial ? ' at 50% OFF' : ''}
        </Typography>
      </div>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.membersDialogDescription}>
          {!!selectedPlan?.type && <AddHouseholdMemberText planType={selectedPlan.type} />}
          {'year' === data?.plan_type && !!selectedPlan && selectedPlan.one_time_purchase_product && (
            <div className={classes.membersDialogShowMore}>
              <div
                className={`${classes.showMore} ${showMore ? 'expanded' : 'collapsed'}`}
                onClick={() => setShowMore((prev) => !prev)}
              >
                <InfoIcon className='icon' />
                {showMore ? (
                  <>
                    <p>
                      The price for NEW Household Members is based on the number of days remaining on your current plan
                      + the number of days on your selected plan.
                    </p>
                    <p>
                      Your have <strong>{expiresIn}</strong> remaining on your plan.
                    </p>
                    {/* {'lifetime' === selectedPlan.type && (
                      <>
                        <p>
                          NEW Household Members will have an Annual Subscription that is synced with the
                          renewal/expiration day of your existing Household Members. You’ll only be charged for the number
                          of days remaining.
                        </p>
                        <p>
                          You have {expiresInDays} days until your add-on renewal day. (although all add-ons will have the
                          same renewal/expiration day, they may have different renewal/expiration years)
                        </p>
                      </>
                    )} */}
                  </>
                ) : (
                  <p>NEW Household Member Pricing</p>
                )}
              </div>
            </div>
          )}
        </div>

        <div className={classes.membersDialogAdded}>
          {!!user.household_members.length && (
            <>
              <div className={classes.membersDialogAddedTitle}>Household members</div>
              {user.household_members.map((member, index) => (
                <ActiveHouseholdMember
                  key={`${index}${email}`}
                  member={member}
                  index={index}
                  handleRemoveHouseholdMember={handleRemoveHouseholdMember}
                />
              ))}
            </>
          )}
        </div>

        {user.household_members.length < 4 && (
          <form onSubmit={handleSubmit(handleAddHouseholdMembers('add-member'))} className={classes.membersDialogForm}>
            <HouseholdMemberFormFields
              emails={Array.from(user.household_members).map(({ email }) => email!)}
              errors={errors}
              control={control}
              register={register}
              trigger={trigger}
              setError={setError}
              clearErrors={clearErrors}
              isAbove13={!!isAbove13}
              formProcessing={formProcessing}
              isValidatingEmail={isValidatingEmail}
              setIsValidatingEmail={setIsValidatingEmail}
            />
          </form>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions} disableSpacing>
        <Button
          disabled={!formTouched || errorExists || formProcessing || user.household_members.length >= 4}
          onClick={handleSubmit(handleAddHouseholdMembers('add'))}
          color='primary'
          variant='outlined'
          endIcon={!errorExists && formProcessing && <CircularProgress size={18} />}
        >
          Add member
        </Button>
        <Button
          onClick={() => {
            onClose();
            if (openTpDialogNext) {
              storeonDispatch('dialog/open', { id: 'manageTravelProtection' });
            }
          }}
          color='primary'
          variant='contained'
          disabled={!user.household_members.length}
        >
          Continue
        </Button>
        <div className={classes.membersDialogActionsRow}>
          <Button onClick={onClose} className={classes.membersDialogActionsRowBtn}>
            Cancel
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default MembersDialog;
