import {
  checkoutStepViewed,
  checkoutStepViewed as checkoutStepCompleted,
  ctaClicked,
  featureFlagEvaluated,
  orderCompleted,
  paymentInfoEntered,
  productAdded,
  productAdded as productRemoved,
  productClicked,
} from './segment/processors';
import { baseAttributes as enrollmentStarted, baseAttributes as signedUp } from './segment/processors/helpers';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
  }
}

/**
 * Segment class.
 */
class Segment {
  /**
   * List of event handlers.
   */
  public readonly eventHandlers = {
    'cta.clicked': this.trackProcessed.bind(this, 'Call to Action Clicked', ctaClicked),
    'checkout.stepViewed': this.trackProcessed.bind(this, 'Checkout Step Viewed', checkoutStepViewed),
    'checkout.stepCompleted': this.trackProcessed.bind(this, 'Checkout Step Completed', checkoutStepCompleted),
    'enrollment.started': this.trackProcessed.bind(this, 'Checkout Started', enrollmentStarted),
    'featureFlag.evaluated': this.trackProcessed.bind(this, 'Feature Flag Evaluated', featureFlagEvaluated),
    'product.added': this.trackProcessed.bind(this, 'Product Added', productAdded),
    'product.clicked': this.trackProcessed.bind(this, 'Product Clicked', productClicked),
    'product.removed': this.trackProcessed.bind(this, 'Product Removed', productRemoved),
    'order.completed': this.trackProcessed.bind(this, 'Order Completed', orderCompleted),
    'payment.infoEntered': this.trackProcessed.bind(this, 'Payment Info Added', paymentInfoEntered),
    'signed.up': this.trackProcessed.bind(this, 'Signed Up', signedUp),
    'page.viewed': this.page.bind(this),
  };

  /**
   * Segment contructor method.
   */
  constructor(key?: string) {
    if (key) {
      this.setKey(key);
    }
  }

  /**
   * Check if segment tracking allowed.
   */
  private isAllowed() {
    return process.browser && window.analytics;
  }

  /**
   * Sets segment key.
   */
  public setKey(key: string) {
    if (this.isAllowed()) {
      window.analytics.load(key);
    }
  }

  /**
   * Tracks given event with processed data.
   */
  public trackProcessed(event: string, processor: (data: Data) => Data, data: Data = {}): void {
    this.track(event, processor(data));
  }

  /**
   * Tracks given event.
   */
  public track(event: string, data: Data = {}): void {
    if (this.isAllowed()) {
      window.analytics.track(event, data);
    }
  }

  /**
   * Identifies the user.
   */
  public identify(id: string): void {
    if (this.isAllowed()) {
      window.analytics.identify(id);
    }
  }

  /**
   * Identifies the user.
   */
  public page(pageName: string, data: Data = {}): void {
    if (this.isAllowed()) {
      window.analytics.page(pageName, data);
    }
  }
}

// Config.get('segmentKey')
export default new Segment();
