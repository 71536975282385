/**
 * Container class.
 */
class Container {
  /**
   * An object of containers.
   */
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private data: { [key: string]: any } = {};

  /**
   * Returns a container item.
   */
  get(name: string) {
    return this.data[name];
  }

  /**
   * Sets a container item.
   */
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  set(name: string, item: any) {
    this.data[name] = item;
  }
}

export default new Container();
