import React from 'react';
import { PlanInterface } from 'api/userEnrollInfo';
import { getConvertedPrice } from 'utils';

type Props = {
  plan: null | PlanInterface;
};

export function PlanPrice({ plan }: Props): JSX.Element {
  const intervalMap: { [k: string]: string } = { month: 'mo', year: 'yr' };
  return (
    <>
      {getConvertedPrice(plan?.price.amount || 0)}
      {plan && !plan.trial && !plan.one_time_purchase_product && plan.period && (
        <span>/{intervalMap[plan.period.unit] ?? plan.period.unit}</span>
      )}
    </>
  );
}
