import React from 'react';
import { Button } from '@mui/material';
import styles from './ProfileInfoBlock.module.scss';
import { ProfileInfoBlockProps } from './ProfileInfoBlock.interface';

const ProfileInfoBlock = ({ label, value, button, buttonOnClick }: ProfileInfoBlockProps): JSX.Element => {
  return (
    <div className={styles.accountProfileInfo}>
      <div className={styles.accountProfileInfoLabel}>{label}</div>
      {value && <div className={styles.accountProfileInfoValue}>{value}</div>}
      {button && buttonOnClick && (
        <Button className={styles.accountProfileInfoBtn} onClick={buttonOnClick}>
          {button}
        </Button>
      )}
    </div>
  );
};

export default ProfileInfoBlock;
