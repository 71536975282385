import React from 'react';

type Props = {
  activeStep: number;
  className: string;
  steps: string[];
};

function MobileStepper({ activeStep, steps, ...props }: Props): JSX.Element {
  return <div {...props}>{steps[activeStep]}</div>;
}

export default MobileStepper;
