import MuiAccordion from './MuiAccordion';
import MuiAccordionDetails from './MuiAccordionDetails';
import MuiAccordionSummary from './MuiAccordionSummary';
import MuiAlert from './MuiAlert';
import MuiBackdrop from './MuiBackdrop';
import MuiButton from './MuiButton';
import MuiCheckbox from './MuiCheckbox';
import MuiFilledInput from './MuiFilledInput';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPaper from './MuiPaper';
import MuiRadio from './MuiRadio';
import MuiSelect from './MuiSelect';
import MuiSwitch from './MuiSwitch';
import MuiTab from './MuiTab';
import MuiTableCell from './MuiTableCell';
import MuiTablePagination from './MuiTablePagination';
import MuiTabs from './MuiTabs';
import MuiTooltip from './MuiTooltip';

export default {
  MuiAccordion,
  MuiAccordionDetails,
  MuiAccordionSummary,
  MuiAlert,
  MuiBackdrop,
  MuiButton,
  MuiCheckbox,
  MuiFilledInput,
  MuiFormControlLabel,
  MuiOutlinedInput,
  MuiPaper,
  MuiRadio,
  MuiSelect,
  MuiSwitch,
  MuiTab,
  MuiTableCell,
  MuiTablePagination,
  MuiTabs,
  MuiTooltip,
};
