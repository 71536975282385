import React, { PropsWithChildren } from 'react';
import styles from './InfoHelp.module.scss';
import { InfoHelpProps } from './InfoHelp.interface';
import cn from 'classnames';
import { InfoRounded } from '@mui/icons-material';

const InfoHelp: React.FC<PropsWithChildren<InfoHelpProps>> = ({ variant = 'default', children, ...props }) => (
  <div
    {...props}
    className={cn({ [styles.InfoHelp]: variant === 'default', [styles.InfoHelpIcon]: variant === 'icon' })}
  >
    {variant === 'icon' && <InfoRounded />} <div>{children}</div>
  </div>
);

export default InfoHelp;
