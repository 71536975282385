import { useEffect } from 'react';
import { FlowState } from 'views/checkout/CheckoutPaymentFlow';

type Props = {
  activeStep?: number;
  state?: FlowState;
};

export function ScrollToTop({ activeStep, state }: Props): null {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep, state]);

  return null;
}
